import { render, staticRenderFns } from "./ImportModal.vue?vue&type=template&id=67118526&scoped=true"
import script from "./ImportModal.vue?vue&type=script&lang=js"
export * from "./ImportModal.vue?vue&type=script&lang=js"
import style0 from "./ImportModal.vue?vue&type=style&index=0&id=67118526&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67118526",
  null
  
)

export default component.exports