import * as ComponentsVueUi from 'components-vue-ui';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from '@/plugins/i18n';
import VIEWS from './config/views.json';
import { authentication } from "@/mixins/authentication";
import Roles from '@/plugins/roles';
import './registerServiceWorker'
import axios from "axios";

Vue.prototype.$appConfig = { CONFIG: window.PROJECT_ENV, VIEWS };
document.title = 'Trafis';

authentication.methods.setUserLoggedState();

axios.defaults.paramsSerializer = params => {
  if (params) {
    const resultParams = [];
    for (const param in params) {
      if (params[param] !== null && Object.hasOwnProperty.call(params, param)) {
        resultParams.push(`${param}=${encodeURIComponent(params[param])}`)
      }
    }

    return resultParams.join('&');
  }

  return '';
}

axios.interceptors.request.use(
  function (config) {
    // if (!config.url.includes('http')) {
    //   const BASE_URL = Vue.prototype.$appConfig.CONFIG.API_BASE_URL;
    //   config.url = BASE_URL + config.url;
    // }

    if (localStorage.getItem("token"))
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

Vue.config.productionTip = false;
Vue.use(Roles);
Vue.use({install: ComponentsVueUi.install});


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

