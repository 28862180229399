<template>
  <div class="mt-5">
    <v-alert v-if="!dependsOnDataSource || (dataSource && !dataSource.length )" outlined type="info" color="info"
      icon="mdi-information" class="mb-5 infoTextAlert">{{$t(infoText)}}</v-alert>
    <v-form ref="multipleSelection">
      <v-autocomplete v-model="inputValue" :items="dataSource" :label="$t(inputLabel)" multiple clearable
        :return-object="configInput.dataSourceIsObj" :rules="[(v) => requiredRule(v, requiredValidation)]"
        @input="update" @change="onChange" :item-text="configInput.itemText" :menu-props="{ contentClass: 'style-content' }"
        :no-data-text="$t(noDataText)"  @update:search-input="setSearchText" 
      >
        <template v-if="filteredItems && filteredItems.length" v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggle()">
            <v-list-item-action class="mr-3">
              <v-icon :color="inputValue.length > 0 ? 'primary' : ''">
                {{ iconPrependItem }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('selectAll') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="{attrs, item}">
          <v-list-item-action class="mr-3" >
            <v-checkbox v-model="attrs.inputValue" :ripple="false" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip bottom :disabled="!isTooltipActivated(`${attrs.id}ListItem`)">
                <template v-slot:activator="{ on }">
                  <div class="text-truncate" v-on="on"
                    @mouseover="setTooltipActivated($event, `${attrs.id}ListItem`)">
                    {{ getValueItem(item) }}
                  </div>
                </template>
                <span>{{ getValueItem(item) }}</span>
              </v-tooltip>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small :color="'var(--borderGray)'" :text-color="'var(--darkGray)'">
            <span v-if="inputValue.length === dataSource.length">{{ $t('all') }}</span>
            <span v-else>{{ item.value || item }}</span>
          </v-chip>
          <span v-if="index === 1 && inputValue.length !== dataSource.length" class="plusElement">
            {{ $t('plusNElements', {number: inputValue.length - 1, element: $t(configInput.plusText).toLowerCase()}) }}
          </span>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>


<script>
import i18n from "@/plugins/i18n";
import { tools } from '../mixins/tools';

  export default {
    name: 'MultipleSelection',
    mixins: [tools],
    props: {
      config: {
        type: Object,
        default: () => ({}),
      },
      currentDataValues: { type: Object },
      submitFormComponent: { type: Boolean },
      resetComponent: { type: Boolean },
      previousStepData: { type: Object },
    },
    data: () => ({
      inputValue: [],
      dirty: false,
      requiredRule: (v, requiredValidation) =>  !!(v && v.length) || !requiredValidation || i18n.t('required'),
      defaultConfigOptions: {
        inputLabel: 'filters',
        inputAttr: 'selectedFilterNames',
        infoText: 'definitionHasNoFilters',
        dataSourceAttr: 'filtersFromInputDefinition',
        plusText: 'filters',
        itemText: 'value'
      },
      tooltipActivated: {},
      searchText: null,
    }),
    computed: {
      
      configInput() {
        return {...this.defaultConfigOptions, ...this.config};
      },
      inputLabel() {
        return this.configInput.inputLabel;
      },
      inputAttr() {
        return this.configInput.inputAttr;
      },
      infoText() {
        return this.configInput.infoText;
      },
      noDataText(){
        return this.config.noDataText ?? 'noDataAutocomplete'
      },
      requiredValidation() {
        return this.configInput.requiredValidation;
      },
      dependsOnDataSource() {
        return this.configInput.dependsOnDataSource;
      },
      iconPrependItem() {
        if (this.isAllItemsSelected) return 'mdi-checkbox-marked';
        else if (this.inputValue.length > 0 && !this.isAllItemsSelected) return 'mdi-minus-box';
        return  'mdi-checkbox-blank-outline';
      },
      dataSource() {
        const { dataSourceAttr, dataSourceIsObj, usePreviousStepData } = this.configInput;
        let dataBasedOn = null
        if (usePreviousStepData && this.previousStepData) {
          dataBasedOn = this.previousStepData[usePreviousStepData]?.[0];
        }
        const dataSource = (this.getDataSource(dataBasedOn)[dataSourceAttr] || []).map(({name, id}) => dataSourceIsObj 
          ? { key: id, value: name }
          : name
        );
        return dataSource
      },
      filteredItems(){
        if(!this.searchText)return this.dataSource
        const filteredItems = this.dataSource.filter(item => {
          const isObject = this.configInput.dataSourceIsObj
          const valueKey = this.configInput.itemText 
          const itemText =  isObject ? item[valueKey].toLowerCase() : item.toLowerCase();
          const search = this.searchText.toLowerCase();

          return itemText.includes(search);
        });
        return filteredItems
      },
      itemsKeySelected(){
        return this.inputValue.map(item => this.getItemKey(item))
      },
      isAllItemsSelected(){
        return this.filteredItems.every(item => this.itemsKeySelected.includes(this.getItemKey(item)))
      }
    },
    watch: {
      dirty(val) {
        this.$emit('dirty', val);
      },
      submitFormComponent(val) {
        if (val) this.$emit('isValid', this.validateInput());
      },
      resetComponent(val) {
        if (val) {
          this.inputValue = [];
          this.dirty = false;
        }
      }
    },
    created() {
      this.inputValue = this.currentDataValues?.[this.inputAttr] || [];
      this.$nextTick(() => {
        this.update(this.inputValue, true);
      })
    },
    methods: {
      setSearchText(event){
        this.searchText = event
      },
      getValueItem(item) {
        return this.configInput.dataSourceIsObj ? item.value : item;
      },
      setTooltipActivated(event, ref) {
        const { target } = event;
        this.$set(this.tooltipActivated, ref, target.offsetWidth < target.scrollWidth);
      },
      isTooltipActivated(ref) {
        return this.tooltipActivated[ref];
      },
      update(event, fromCreated = false) {
        const isValid = !fromCreated ? this.validateInput() : true;
        const valueToEmmit = isValid && event.length ? {[this.inputAttr]: event} : null;
        this.$emit('isValid', isValid);
        this.$emit('update', valueToEmmit);
      },
      onChange() {
        this.dirty = true;
      },
      validateInput() {
        return this.$refs.multipleSelection?.validate();
      },
      getItemKey(item){
        return this.configInput.dataSourceIsObj ? item.key : item
      },
      toggle() {
        this.dirty = true;
        const isSelectingAll = !this.isAllItemsSelected
        if(isSelectingAll){
          const itemsToAdd = this.filteredItems.filter(item => !this.itemsKeySelected.includes(this.getItemKey(item)))
          this.inputValue = [ ...this.inputValue, ...itemsToAdd]
        }else{
          const filteredKeys = this.filteredItems.map(item => this.getItemKey(item))
          this.inputValue = this.inputValue.filter(item => !filteredKeys.includes(this.getItemKey(item)))
        }
        this.$nextTick(() => { 
          this.update(this.inputValue);
        })
      },
    },
  }
</script>

<style lang="scss" scoped>

.plusElement {
  color: var(--fontColorTerciary);
  font-size: 14px;
  font-style: italic;
}

.infoTextAlert {
  font-size: 16px;
}

.style-content .v-list {
  max-width: 398px;
}
</style>