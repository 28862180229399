<template>
  <div
    :style="{ 'max-height': `calc(100vh - ${headerContainer}px)` }"
    class="container-list"
  >
    <div
      v-for="(listItem, index) in filteredData"
      :key="index"
      class="card-list"
    >
      <Card>               
        <div v-if="loading && listItem.processId === selectedItem.id">
          <v-progress-linear color="var(--primary)" indeterminate rounded></v-progress-linear>
        </div>          
        <v-expansion-panels>         
          <v-expansion-panel>
            <v-expansion-panel-header class="d-flex justify-space-between">
              <div class="d-flex align-center gap">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-avatar v-on="on" size="40px">
                      <img
                        v-if="listItem.user.photoUrl"
                        max-width="40px"
                        max-height="40px"
                        :src="listItem.user.photoUrl"
                        alt="user_photo"
                      />
                      <v-icon max-width="40px" max-height="40px" size="50" v-else
                        >mdi-account-circle</v-icon
                      >
                    </v-avatar>
                  </template>
                  <span v-if="listItem.user.name">{{
                    listItem.user.name || listItem.user.email
                  }}</span>
                </v-tooltip>                
                <span v-if="listItem.status === 'UPLOADING'" class="gifStatus" :class="listItem.status">
                  <img src="../../public/img/icons/uploadFile.gif" max-width="40px" max-height="40px" class="gifStatusImg">
                </span>
                <span v-else-if="listItem.status === 'GENERATING_REPORT'" class="gifStatus" :class="listItem.status">
                  <img src="../../public/img/icons/generatingReportFile.gif" max-width="40px" max-height="40px" class="gifStatusImg">
                </span>
                <div v-else class="status">
                  <div class="statusIcon" :class="listItem.status">
                    <v-icon v-if="listItem.status === 'FINISHED'"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else-if="listItem.status === 'FAIL'"
                      >mdi-alert-circle</v-icon
                    >
                    <v-icon v-else-if="listItem.status === 'RUNNING'"
                      >mdi-cached</v-icon
                    >
                    <v-icon v-else-if="listItem.status === 'VALIDATIONS'"
                      >mdi-alert</v-icon
                    >
                    <span class="percentage" v-if="listItem.status === 'FINISHED'"
                      >100%</span
                    >
                    <span
                      class="percentage"
                      v-else-if="listItem.status === 'FAIL'"
                      >0%</span
                    > 
                    <span class="percentage" v-else>
                      
                      {{
                        Math.round((listItem.numberReadPackages * 100) / listItem.totalNumberPackages)
                      }}%
                    </span>
                  </div>
                </div>

                <div class="headerItem flex-grow-1">
                  {{ listItem.inputFileName }} ({{ listItem.dateTitleFormatted }})
                </div>
              </div>
                <Actions class="justify-end"
                  v-if="!['RUNNING', 'DELETING', 'UPLOADING', 'GENERATING_REPORT'].includes(listItem.status)"
                  :actions="cardActions(listItem.status)"
                  @action-selected="onActionSelected($event, listItem)"
                />
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <div class="table-container">
                <v-simple-table>
                  <tbody>
                    <tr v-for="(value, key) in showedItem(listItem)" :key="key" >
                      <td class="text-left">{{ traduceItem(key) }}</td>
                      <td v-if="isArrayType(value)" class="pt-3 pb-3">
                        <ul class="d-flex">
                          <li v-for="(val, index) in value" :key="index">
                            <v-chip v-if="key === 'selectedFilterNames' && value.length" class="mr-3">
                              {{ val }}
                            </v-chip>
                            <v-chip v-else-if="key === 'variables' && value.length" class="mr-3">
                              {{ val.key }}: {{ val.value }}
                            </v-chip>
                            <span v-else>
                              {{ val }}
                              {{ index !== value.length - 1 ? ", " : "" }}
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td v-else>
                        <template>
                          <td v-if="key === 'user'" class="user-mail">{{ value.email }}</td>
                          <td v-else>{{ value }}</td>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>

              <v-pagination
                v-if="paginationLength > 1"
                v-model="page"
                :length="paginationLength"
                circle
                class="mt-4"
              ></v-pagination>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </Card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import i18n from "@/plugins/i18n";
import { getDownloadURL } from "firebase/storage";

export default {
  name: "ProcessList",
  data: () => ({
    selectedItem: undefined,
    keys: undefined,
    page: 1,
    paginationLength: undefined,
    pageElements: 10,
    filteredData: [],
    loading: false,
  }),
  props: {
    dataComponent: { type: Array },
    headerHeight: { type: Number },
  },
  mixins: [tools, firebaseTools],

  methods: {
    ...mapActions(["setItemToConfirmAction", "setItemToAddEdit", "setShowSnackBar"]),

    showedItem(item) {
      return Object.keys(item).reduce((obj, key) => {
        if (this.showTemplate(key, item[key])) {
          obj[key] = item[key];
        }
        return obj;
      }, {});
    },
    showTemplate(key, value) {
      if (['status', 'numberReadPackages', 'totalNumberPackages', 'dateTitleFormatted', 'validationFileName'].includes(key)) return false;
      if (key === 'variables') return value && value.length > 0;
      return true;
    },
    setData() {
      for (const {status, user:{email,name,photoUrl},inputFileName,inputDescriptorInfo,numberReadPackages,totalNumberPackages,validationFileName,...element} of this.dataComponent) {
        let startDate = new Date(element.startDate.seconds * 1000);
        let data = {
     
          processId: element.id,
          status,
          date: this.getFormattedDateField(startDate),
          dateTitleFormatted: this.getFormattedDate(
            new Date(element.startDate.seconds * 1000)
          ),

          startTime: this.getFormattedTime(startDate),

          endTime: this.getEndTime(element),
          user: {
            email,
            name,
            photoUrl,
          },
          inputFileName,
          delimiterColumns: i18n.t(element.delimiter) ? i18n.t(element.delimiter) : "--",
          inputDefinitionName: inputDescriptorInfo[0]?.value ?? "--",
          selectedFilterNames: element.selectedFilterNames?.length
            ? element.selectedFilterNames
            : "--",
          outputDefinitionNames: element.outputDescriptorInfo?.length
            ? element.outputDescriptorInfo.map((value) => value.value)
            : "--",
          numberReadPackages,
          totalNumberPackages,
          validationFileName,
          variables: element.variables
        };

        this.filteredData.push(data);
      }
    },
    getFormattedDate(date) {
      let dayOfStartDate = String(date.getDate()).padStart(2, "0");
      let yearOfStartDate = date.getFullYear();
      let today = new Date();
      let yearOfToday = today.getFullYear();
      let month = date.toLocaleString("default", { month: "long" });

      return (
        dayOfStartDate +
        " " +
        month.charAt(0).toUpperCase() +
        month.slice(1) +
        " " +
        (yearOfToday === yearOfStartDate  ? ""  : yearOfStartDate) + 
        " - " +
        this.getFormattedTime(date)  
      );
    },
    getFormattedDateField(date) {
      let dayOfStartDate = String(date.getDate()).padStart(2, "0");
      let month = date.toLocaleString("default", { month: "long" });
      let yearOfStartDate = date.getFullYear();
      return (
        dayOfStartDate +
        " " +
        month.charAt(0).toUpperCase() +
        month.slice(1) +
        " " +
        yearOfStartDate
      );
    },
    getEndTime(element) {
      let endTime = element.endDate; //this.filteredData.endDate

      if (!endTime) {
        element.endTime = "--";
      } else {
        let endDate = new Date(endTime.seconds * 1000);
        element.endTime = this.getFormattedTime(endDate);
      }

      return element.endTime;
    },
    getFormattedTime(date) {
      return (
        this.checkHour(date.getHours()) +
        ":" +
        this.checkHour(date.getMinutes()) +
        ":" +
        this.checkHour(date.getSeconds())
      );
    },
    checkHour(hour) {
      return hour < 10 ? "0" + hour : hour;
    },
    isStringType() {
      if (this.dataView[0]?.values)
        return typeof this.dataView[0].values[0] === "string";
    },
    isArrayType(value) {
      return Array.isArray(value);
    },
    onActionSelected(event, item) {
      this.selectedItem = this.dataComponent.find(
        (element) => element.id === item.processId
      );

      let copyItem = this.deepCopyFunction(this.selectedItem);
      let path;
      
      switch (event.action) {
        case "deleteProcess":
          this.setItemToConfirmAction({
            title: i18n.t('deleteProcessTitle'),
            text: i18n.t('deleteProcessQuestion'),
            data: {
              documentId: copyItem.id,
              action: 'deleteProcess'
            }
          })
          break;
        case "download-validations":
          path = copyItem.id +'/'+ item.validationFileName
          this.downloadFileFromStorage(path);
          break;
        case "download":
          path = copyItem.id + ".zip"
          this.downloadFileFromStorage(path);
          break;
      }
    },
    downloadFileFromStorage(path) {
      this.setShowSnackBar({
        color: 'info',
        msg: i18n.t('downloadingFile'),
        icon: "mdi-information",
      })

      this.loading = true;
      const fileRef = this.createStorageReference(path);
      
      // Get the download URL
      getDownloadURL(fileRef)
        .then((url) => {          
          this.downloadFile(
            url,
            "get",
            path,
            null
          ).then(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.setShowSnackBar({
            color: 'error',
            msg: i18n.t('errorDownloadFile'),
            icon: "mdi-alert-circle",
          })
           this.loading = false;         
        });  
    },

    cardActions(status) {
      return this.setActions(this.actualView.componentActions).filter((a) =>
        a.status.includes(status)
      );
    },
  },
  watch: {
    dataComponent() {
      this.filteredData = [];
      this.setData();
    },
    page() {
      this.setData();
    },
  },

  mounted() {
    this.setData();
  },

  computed: {
    ...mapState(["actualView", "dataView", "userLogged", "itemToSelect"]),
    headerContainer() {
      return this.headerHeight + 20;
    }
  },
};
</script>

<style lang="scss" scoped>
.container-list {
  overflow: auto;
  padding: 6px;
  margin-top: -6px;
  .card-list:not(:last-child) {
    margin-bottom: 20px;
  }
  .card-list:last-child {
    margin-bottom: 10px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)> .v-expansion-panel--active {
    border-radius: 10px;
  }
}
::v-deep {
  .card-after-header {
    display: flex;
  }
}

.status {
  color: var(--fontColorTerciary);
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
  > div {
    display: flex;
    flex-wrap: wrap;
    span {
      line-height: 14px;
    }
  }
  .v-btn {
    border-color: var(--borderGray);
    color: var(--fontColorSecondary);
    text-transform: none;
    font-weight: 400;
    .v-icon {
      font-size: 0.9rem;
    }
  }
  .v-icon {
    padding: 5px;
    font-size: 24px;
  }
  .statusIcon {
    border-radius: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .percentage {
      font-weight: 700;
      padding-right: 5px;
    }
    &.FAIL,
    &.STOPPED {
      border: 1px solid var(--red);
      color: var(--red);
      .v-icon {
        color: var(--red);
      }
    }
    &.FINISHED {
      border: 1px solid var(--green);
      color: var(--green);
      .v-icon {
        color: var(--green);
      }
    }
    &.VALIDATIONS {
      border: 1px solid var(--orange);
      color: var(--orange);
      .v-icon {
        color: var(--orange);
      }
    }
    &.RUNNING {
      border: 1px solid var(--blue);
      color: var(--blue);
      .v-icon {
        color: var(--blue);
        animation: spin-animation 2s linear infinite;
      }
    }
  }
  @keyframes spin-animation {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.gifStatus {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  &.UPLOADING {
      border: 1px solid var(--secondary);
  }
  &.GENERATING_REPORT {
    border: 1px solid var(--primary);
  }
}

.gifStatusImg {
  width: 28px;
}

ol,
ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.table-container {
  border: 1px solid var(--borderGray);
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  tr .text-left {
    color: var(--fontColorTerciary) !important;
    font-weight: 400;
  }
  tr td {
    width: 50%;
    color: var(--fontColor);
  }
}

.headerItem {
  font-size: 18px;
}
.gap {
  gap: 12px;
  color: var(--fontColor);
}
.user-mail {
  color: var(--link) !important;
  text-decoration: underline;
}
</style>
