<template>
  <div v-resize="onResize">
    <slot name="breadcrumbsWrp"></slot>
    <!-- TABS -->
    <v-tabs
      class="menu-tabs"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mb-5'"
      v-if="viewTabs && frameType === 'tabs'"
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in viewTabs.tabs"
        :key="tab.name"
        :to="{ name: tab.name }"
        :id="tab.name"
        >{{ traduceItem(tab.name) }}</v-tab
      >
    </v-tabs>
    <div v-if="actualGroup">
      <router-view></router-view>
      <v-progress-circular
        v-if="loadingPage && !Array.isArray(dataComponent)"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <div v-else>
        <div
          class="d-flex align-center"
          :class="
            !actualView.parent && !(actualView.meta && actualView.meta.parent)
              ? 'justify-end mb-4'
              : 'justify-space-between'
          "
          ref="searchActions"
          v-if="
            (actualView.name !== 'ProcessHistory') || 
            (processData && processData.length && actualView.name === 'ProcessHistory')
          "
        >
          <SearchEngine
            v-if="((actualView.search && dataView && dataView.length && actualView.name !== 'ProcessHistory') || (processData && processData.length && actualView.name === 'ProcessHistory')) && 
            actualView.search"
            :toSearch="toSearch"
            :autofocus="false"
            class="flex-grow-1"
            @search="debounceSearch"
            :results="dataComponent?.length"
            :fieldToSearchWhenWriteOnInput="toSearch?.fields[0]?.field"
            :key="keyToSearch"
          />
          <div
            class="name-detail-header"
            v-if="(actualView.meta && actualView.meta.parent && dataView) || (actualView.parent && dataView)"
          >
            {{ dataView.name }}
          </div>
          <Actions
            class="ml-2 mr-0"
            v-if="((actualView.actions && actualView.actions.length) || (actualView.parent && actualView.parent.actions && actualView.parent.actions.length)) && 
                  ((actualView.name === 'ProcessHistory' && processData?.length) || (actualView.name !== 'ProcessHistory'))"
            :type="typeActions"
            :actions="actions"
            :background="background"
            :btnTriggerColor="colorMenuActions"
            @action-selected="onActionSelected"
          ></Actions>
        </div>
         
        <component
          v-if="
            (dataComponent && Array.isArray(dataComponent) && dataComponent.length &&
              (!actualView.meta || (actualView.meta && !actualView.meta.parent))) ||
            (typeof dataComponent === 'object' && Object.keys(dataComponent).length && actualView.meta && actualView.meta.parent) || 
            (actualView.parent)
          "
          :is="moduleComponent"
          :headerHeight="headerHeight"
          :dataComponent="dataComponent"
          @table-action="onActionSelected"
        />       
        <div v-else>
          <NoData :text="configNodata.text" :image="configNodata.image"></NoData>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { firebaseTools } from "@/mixins/firebase-tools";
import { tools } from "@/mixins/tools";
import { uuid } from 'vue-uuid';
import List from "@/components/List";
import ListDetail from "@/components/ListDetail";
import ProcessList from "@/components/ProcessList";
import Detail from "@/components/Detail";
import DetailDictionary from "@/components/DetailDictionary";
import NoData from "./NoData.vue";
import i18n from "@/plugins/i18n";
import editableDataFields from "@/mixins/editable-data-fields";
import { getDownloadURL } from "firebase/storage";
import editableDataFieldsStepper from "@/mixins/editable-data-fields-stepper";

export default {
  name: "ViewFrame",
  mixins: [firebaseTools, tools],
  components: {
    ListDetail,
    Detail,
    DetailDictionary,
    List,
    ProcessList,
    NoData,
  },
  props: {
    frameType: { type: String },
  },
  data: () => ({
    tab: null,
    viewTabs: undefined,
    timeout: undefined,
    search: undefined,
    dataComponent: undefined,
    searching: false,
    headerHeight: undefined,
    lastSearch: undefined,
  }),
  computed: {
    ...mapState([
      "actualGroup",
      "actualView",
      "loadingPage",
      "dataView",
      "envTheme",
      "headerAndBreadcrumsHeight",
      "processData",
      "inputDefinitionsData",
      "outputDefinitionsData",
      "dictionariesData",
      "listsData",
      "userLogged",
      "unsubscribe",
      "itemToImport",
      "dictionaryToImport",
    ]),
    keyToSearch(){
      return JSON.stringify(this.deepCopyFunction(this.toSearch))
    },
    toSearch() {
      const containedData = this.deepCopyFunction(this.actualView.name === "ProcessHistory" ? this.processData : this.dataView);

      if(!containedData || !containedData.length) return [];
     
      const isAdvanced = typeof this.actualView.search !== 'boolean';
      let fields = [];
      if (!isAdvanced) {
        fields = [
          {
            field: 'name',
            label: i18n.t('name'),
            type: 'text',
            preselected: this.lastSearch?.name || '',
          },
        ]
      } else {
        fields = this.actualView.search.fields.map(({ field, label, type, deepField, invalidDateText }) => {
          const data = [
            ...new Set(
              containedData
                .map((el) => {
                  const currentField = el[field];
                  if (Array.isArray(currentField)) {
                    return currentField.map((elem) => elem[deepField]);
                  } else {
                    return (currentField && deepField && currentField[deepField]) || currentField;}
                })
                .filter(Boolean)
                .flatMap((el) =>
                  (type === 'select'
                    ? (i18n.te(el) && i18n.t(el)) || el
                    : el
                  ).toString()
                )
            ),
          ];

          if (data.length) {
            let fieldItem = {
              field,
              label: i18n.t(label || field),
              type,
              data: type !== 'date' && data,
              preselected: (this.lastSearch && this.lastSearch[field]) || null,
            };
            if (invalidDateText) fieldItem = {...fieldItem, invalidDateText: i18n.t(invalidDateText)};
            return fieldItem;
          } else return;
        }).filter(Boolean)
      }

      return {
        label: i18n.t(`searchLabel.${this.actualView.name}`),
        resultsLabel: i18n.t('resultsFound'),
        selectAllLabel: i18n.t('selectAll'),
        hasAdvancedSearch: isAdvanced,
        fields,
      };
    },
    configNodata() {
      return {
        text: i18n.t(
          !this.searching ? `noData.${this.actualView.name}` : "noSearchData"
        ),
        image: !this.searching ? "noData" : "search",
      };
    },
    moduleComponent() {
      return this.actualView && this.actualView.nestedComponent
        ? this.actualView.nestedComponent
        : null;
    },
    actions() {
      const actualView = this.actualView.parent || this.actualView
      return this.setActions(actualView.actions.map(obj=>({...obj,disabledTooltip: !!obj.label})));
    },
    typeActions() {
      const actualView = this.actualView.parent || this.actualView;
      const { optionsActions: { type } = {} } = actualView || {};
      return type || 'text-buttons';
    },
    colorMenuActions() {
      const actualView = this.actualView.parent || this.actualView;
      const { optionsActions: { color } = {} } = actualView || {};
      return color || undefined;
    },
    background() {
      const actualView = this.actualView.parent || this.actualView;
      const { optionsActions: { background } = {} } = actualView || {}
      return background || false;
    }
  },
  methods: {
    ...mapActions([
      "setItemToAddEdit",
      "setItemToConfirmAction",
      "setLoadingPage",
      "setRouteVuex",
      "setDataView",
      "setItemToImport",
      "setItemToImportDictionary",
      "setItemToAddEditStepper",
    ]),
    onResize() {
      this.$nextTick(() => {
        this.headerHeight =
          this.$refs && this.$refs.searchActions
            ? this.headerAndBreadcrumsHeight +
              this.$refs.searchActions.clientHeight
            : this.headerAndBreadcrumsHeight;
      });
    },
    debounceSearch(selectedValues) {
      this.searching = false
      this.setDataComponent()
      let fieldsSearched = selectedValues && Object.keys(selectedValues).filter(
        (item) =>  selectedValues[item]?.length && selectedValues[item]
        ) ;
      this.searching = fieldsSearched.length ? true : false;
      if (this.searching) {
        this.lastSearch = selectedValues;
        let data = [];
        this.dataComponent.forEach(item => {
          let containField = true;
          fieldsSearched.forEach(field => {
            if(typeof this.dataComponent[0][field] === 'object' && !Array.isArray(item[field]) && item[field]){
              const deepField = this.actualView.search.fields[this.actualView.search.fields.findIndex(el=>el.field===field)]['deepField']
              if(field !== 'startDate') {
                if(!item[field][deepField].toLowerCase().includes(selectedValues[field].toLowerCase())) containField=false
              } else if (deepField === 'seconds') {
                const rawDate = new Date(item[field][deepField]*1000)
                const month = String(rawDate.getMonth() + 1).padStart(2, "0")
                const day = String(rawDate.getDate()).padStart(2, "0")
                const date = `${rawDate.getFullYear()}-${month}-${day}`
                  if(date !== selectedValues[field]) containField = false
              }
                
              } else  if (typeof selectedValues[field] === "string" && typeof item[field] === "string") {
              if(!item[field].toLowerCase().includes(selectedValues[field].toLowerCase())) containField = false
            } else if (Array.isArray(item[field])) {
             if (Array.isArray(selectedValues[field])) {
              selectedValues[field].forEach(element => {
                if (!item[field].includes(element)) containField = false;
              })
              } else if ((!item[field].includes(selectedValues[field]))) {
              containField = false
              }

            } else if (typeof item[field] === 'string' && Array.isArray(selectedValues[field]) && !selectedValues[field].includes(item[field])) {
              containField = false;
            } else if(!item[field]) {
              containField = false
            } else if(typeof item[field] === 'number'){
              if(selectedValues[field] != item[field]) containField = false
            } 
          })
          if (containField) data.push(item);
        })
        this.dataComponent = data
      } else {
        this.lastSearch = undefined;
      }
    },

    iterateTabs(tabs) {
      for (const tab of tabs) {
        if (tab.name === this.$route.name) return true;
      }
    },
    getTabs(view) {
      if (!view.parent && !view.parent?.tabs) this.viewTabs = view.tabs;
      else if (view.parent && !view.parent.tabs) this.getTabs(view.parent);
    },
    saveData(dictionaries) {
      this.insertDocument(this.actualView.collection, dictionaries).then(
        (response) => {
          console.log(response);
        }
      );
    },
    updateData(documentId, dictionariesUpdated) {
      this.updateDocument(
        this.actualView.collection,
        documentId,
        dictionariesUpdated
      ).then((response) => {
        console.log(response);
      });
    },
    deleteData(documentId) {
      const actualView = this.actualView.parent || this.actualView
      this.deleteDocument(actualView.collection, documentId);
    },
    onActionSelected(event) {
      if(event.action === 'deleteAllProcess') {
        return this.setItemToConfirmAction({
            title: i18n.t('deleteAllProcessTitle'),
            text: i18n.t('deleteAllProcessQuestion'),
            data: {
              action: 'deleteAllProcess'
            }
          })
      } 

      const actualView = this.actualView.parent || this.actualView
      let fields = this.deepCopyFunction(
        editableDataFields.computed[actualView.name]()[event.action]
          ?.fields
      );
      let configEditableDataFields = this.deepCopyFunction(
        editableDataFields.computed[actualView.name]()[event.action]
          ?.config
      );
      let objectItemToAddEdit = {};
      let dataSource = this.getDataSource();
      const actions = [actualView.actions, this.actualView.tableActions, actualView.componentActions].flat().filter(Boolean)
      let actionOnActualView = actions.find(
        (action) => action.action === event.action
      );
      let globalValues =
        actionOnActualView && actionOnActualView.globalValues
          ? this.deepCopyFunction(this.dataView[actionOnActualView.globalValues])
          : this.deepCopyFunction(this.dataView);
      if (actionOnActualView.dynamicRequireds) {
        actionOnActualView.dynamicRequireds.forEach((required) => {
          if (!this.dataView[required.dependsOn]) {
            let findField = fields.find(
              (field) => field.name === required.field
            );
            if (findField.validators)
              findField.validators.required = { msg: i18n.t("required") };
            else
              findField.validators = { required: { msg: i18n.t("required") } };
          }
        });
      }
      let updateValuesFB = actionOnActualView.updateValuesFB
        ? actionOnActualView.updateValuesFB
        : null;
      if (
        event.action === "edit" ||
        event.action === "addColumn" ||
        event.action === "editParent"
      ) {
        if(globalValues && globalValues.length && actionOnActualView?.restOneToPosition) globalValues.forEach(value => value.position = value.position + 1);
        objectItemToAddEdit = {
          section: actualView.name,
          fields,
          configEditableDataFields,
          currentDataValues: this.dataView,
          globalValues,
          dataSource,
          updateValidations: ['checkUniqueValuesValidations'],
          data: {
            params: {
              collectionName: actualView.get[0].collection,
              documentId: this.$router.history.current.params.id,
              updateValuesFB,
              msgAction: event.action,
              addPositionDynamicByDefault:
                  actionOnActualView.addPositionDynamicByDefault ? true : false,
            },
            method: "updateDocument",
          },
        };
      }
      let { 
        globalValues: globalValuesForAction,
        addPositionDynamicByDefault = false
      } = actionOnActualView || {};

      const section = actualView.name;
      const editableFieldsStepper = editableDataFieldsStepper.computed[section] && this.deepCopyFunction(editableDataFieldsStepper.computed[section]()[event.action]);
      let { config: stepperConfig, steps } = editableFieldsStepper || {};
      let dictionary;
      
      switch (event.action) {
        case "add":
          this.setItemToAddEdit({
            section: actualView.name,
            fields,
            globalValues,
            configEditableDataFields,
            dataSource,
            data: {
              params: { collectionName: actualView.get[0].collection },
              method: "insertDocument",
            },
          });
          break;
        case "delete":
          this.setItemToConfirmAction({
            title: i18n.t(`delete${actualView.name}`),
            text: i18n.t(`delete${actualView.name}Question`),
            data: {
              params: {
                collectionName: actualView.get[0].collection,
                documentId: this.$router.history.current.params.id,
                name: this.dataView.name,
              },
              method: "deleteDocument",
            },
          });
          break;
        case "edit":
        case "addColumn":
        case "editParent":
          if (actionOnActualView && actionOnActualView.requestDataSouce) {
            dataSource[actionOnActualView.requestDataSouce.atributeDataSource] =
              [];
            actionOnActualView.requestDataSouce.requests.forEach(
              (request, index) => {
                const data = this[this.collectionStore(request.collection)]
                let dataOfRequest = data.map((element) => {
                  return { name: element.name, id: element.id };
                });
                let itemDataSource = actionOnActualView.requestDataSouce.addHeaders ? [
                  { header: request.header },
                  ...dataOfRequest,
                ] : dataOfRequest;
                dataSource[
                  actionOnActualView.requestDataSouce.atributeDataSource
                ] = [
                    ...dataSource[
                    actionOnActualView.requestDataSouce.atributeDataSource
                    ],
                    ...itemDataSource,
                  ];
                if (
                  actionOnActualView.requestDataSouce.requests.length - 1 ===
                  index
                ) {
                  this.setItemToAddEdit(objectItemToAddEdit);
                }
              }
            );
          } else if (actionOnActualView?.requestCollection) {
            const data = this[this.collectionStore(actionOnActualView.requestCollection)]
            objectItemToAddEdit.globalValues = data;
            this.setItemToAddEdit(objectItemToAddEdit);
          } else this.setItemToAddEdit(objectItemToAddEdit);
          break;
        case "goBack":
          if (actualView.meta?.parent) {
            this.unsubscribeElement('Detail')
            this.unsubscribeElement('DetailDictionary')
            this.setRouteVuex(actualView.meta.parent);
          }
          break;
        case "clone":
          if (actionOnActualView?.requestCollection) {
            this.getAllData(actionOnActualView.requestCollection)
              .then(data => {
                this.setItemToAddEdit({
                  section: actualView.name,
                  fields,
                  globalValues: data,
                  currentDataValues: { ...this.dataView, name: this.setCloneName(this.dataView, data) },
                  configEditableDataFields,
                  dataSource,
                  cloneItem: true,
                  data: {
                    params: { collectionName: actualView.get[0].collection },
                    method: "insertDocument",
                  },
                })
              })
          }
          break;
        case 'importColumns':
          this.setItemToImport({
            config: {
              title: i18n.t('importColumns'),
              secondaryButton: {
                icon: "mdi-close",
                text: i18n.t("cancel")
              },
              primaryButton: {
                icon: "mdi-cloud-upload",
                text: i18n.t('import')
              }
            },
            currentDataValues: {
              fileType: null,
              sheetName: null,
              alias: null,
              position: null,
              nullable: null,
              dataType: null,
              filterColumnName: null,
              filterValue: null
            }
          })
          break;
        case 'deleteDictionary':
          this.setItemToConfirmAction({
            title: i18n.t(`delete${actualView.name}`),
            text: i18n.t(`delete${actualView.name}Question`),
            data: {
              params: {
                collectionName: actualView.get[0].collection,
                documentId: this.$router.history.current.params.id,
                name: this.dataView.name,
              },
              method: "deleteDocument",
            },
            alerts: [
              {
                type: 'warning',
                icon: 'mdi-alert',
                text: i18n.t('deleteDictionaryAlert'),
              },
            ]
          });
          break;
        case 'importDictionary':
          this.setItemToImportDictionary({
            config: {
              title: i18n.t('importDictionary'),
              secondaryButton: {
                icon: "mdi-close",
                text: i18n.t("cancel")
              },
              primaryButton: {
                icon: "mdi-cloud-upload",
                text: i18n.t('import')
              },
              downloadButton: {
                icon: "mdi-cloud-download",
                text: i18n.t('download-template')
              },
              data: {
                dictionaryId: this.$router.history.current.params.id,
              },
            },
            currentDataValues: {
              fileType: null,
              sheetName: null,
              alias: null,
              position: null,
              nullable: null,
              dataType: null,
              filterColumnName: null,
              filterValue: null,
              idDictionary: null,
            }
          })
          break;
        case 'downloadDictionaryTemplate':
          this.downloadDictionaryTemplate();
          break;
        case 'addDictionary':
          globalValues = globalValuesForAction ? this.deepCopyFunction(this.dataComponent[globalValuesForAction]) : this.deepCopyFunction(this.dataComponent);
          this.setItemToAddEditStepper({
            section,
            dataSource,
            globalValues,
            steps,
            addPositionDynamicByDefault,
            action: 'add',
            config: stepperConfig,
            data: {
              params: {
                collectionName: actualView.get[0].collection,
                msgAction: event.action,
              },
              method: "insertDocument",
            },
          });
          break;
        case "editDictionary":
          globalValues = this[this.collectionStore(actionOnActualView.requestCollection)]
          dictionary = { ...this.dataView };

          this.setItemToAddEditStepper({
            section,
            dataSource,
            globalValues,
            steps,
            addPositionDynamicByDefault,
            action: 'edit',
            config: stepperConfig,
            currentDataValues: this.prepareDictionaryToStepper(dictionary),
            data: {
              params: {
                collectionName: actualView.get[0].collection,
                documentId: this.$router.history.current.params.id,
                msgAction: event.action,
              },
              method: "updateDocument",
            },
          });
          break;
        case "cloneDictionary":
          globalValues = this[this.collectionStore(actionOnActualView.requestCollection)]
          dictionary = { ...this.dataView, name: this.setCloneName(this.dataView, globalValues) };
          this.setItemToAddEditStepper({
            section,
            dataSource,
            globalValues,
            steps,
            addPositionDynamicByDefault,
            action: 'add',
            config: stepperConfig,
            currentDataValues: this.prepareDictionaryToStepper(dictionary),
            data: {
              params: {
                collectionName: actualView.get[0].collection,
                documentId: this.$router.history.current.params.id,
                msgAction: event.action,
              },
              method: "insertDocument",
            },
          });
          break;
      }
    },
    prepareDictionaryToStepper(dictionary) {
      const result = { name: dictionary.name, dictionaryKeys: [], dictionaryValues: [], data: new Map() };
      if (!dictionary.dictionaryValues || dictionary.dictionaryValues.length === 0) {
        result.dictionaryKeys.push({ uuid: uuid.v4(), value: '' });
        result.dictionaryValues.push({ uuid: uuid.v4(), value: '' });
        result.data.set(0, [{ uuid: result.dictionaryKeys[0].uuid, value: '' }]);
        result.data.set(0, [{ uuid: result.dictionaryValues[0].uuid, value: '' }]);
        return result;
      }
      dictionary.dictionaryValues.forEach((row, index) => {
        row.row.forEach(attribute => {
          const attrToPushData = attribute.cellIsKey ? 'dictionaryKeys' : 'dictionaryValues';
          if (index === 0) {
            result[attrToPushData].push({ uuid: uuid.v4(), value: attribute.entry.key });
          }
          if (!result.data.has(index)) {
            result.data.set(index, []);
          }
          const id = result[attrToPushData].find(key => key.value === attribute.entry.key).uuid;
          result.data.get(index).push({ uuid: id, value: attribute.entry.value });
        })
      });
      return result;
    },
     setDataComponent() {
      if (this.actualView.name === "ProcessHistory") {
        this.dataComponent = this.processData
        this.setLoadingPage(false)
        this.unsubscribeElement('Detail');
        this.unsubscribeElement('DetailDictionary');
      } else if (!this.actualView.meta?.parent && !this.actualView.parent) {
        this.dataComponent  = this[this.actualView.name.charAt(0).toLowerCase() + this.actualView.name.slice(1) + "Data"]
        if(this.searching && this.lastSearch) this.debounceSearch(this.lastSearch) 
        this.unsubscribeElement('Detail');
        this.unsubscribeElement('DetailDictionary');
      } else {
        this.dataComponent = this.dataView
      }
    },
    downloadDictionaryTemplate() {
      this.setShowSnackBar({
        color: 'info',
        msg: i18n.t('downloadingFile'),
        icon: "mdi-information",
      })
      this.loading = true;
      const fileRef = this.createStorageReference('templates/trafis_dictionary_template.csv');
      // Get the download URL
      getDownloadURL(fileRef)
        .then((url) => {          
          this.downloadFile(
            url,
            "get",
            'trafis_dictionary_template.csv',
            null
          ).then(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.setShowSnackBar({
            color: 'error',
            msg: i18n.t('errorDownloadFile'),
            icon: "mdi-alert-circle",
          })
           this.loading = false;         
        });     
    },
  },
  watch: {
    loadingPage(value) {
      if (!value) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.onResize();
        }, 1);
      }
    },
    processData() {
      if (this.processData && this.actualView.name === "ProcessHistory") this.setDataComponent();
    },
    dataView() {
      if (this.dataView && this.actualView.name !== "ProcessHistory") this.setDataComponent();
    },
    actualView(newView, oldView) {
      if (newView.parent.name !== oldView.parent.name) this.tab = 0;
      this.getTabs(this.actualView);
    },
    userLogged(){
      this.dataComponent = undefined;
    },
    actualGroup(){
      this.searching = false;
    }
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
  mounted() {
    if(this.actualView.name === 'ProcessHistory' && this.processData) this.setDataComponent()
    this.getTabs(this.actualView);
    if (this.actualView.parent && this.actualView.parent.tabs) {
      this.tab = this.actualView.parent.tabs.indexOf(this.actualView);
    } 
    if(!this.actualView.meta?.parent && !this.actualView.parent) this.setDataView(this[this.actualView.name.charAt(0).toLowerCase()+this.actualView.name.slice(1)+"Data"])
  },
};
</script>
<style lang="scss" >
.v-main {
  color: var(--fontColor);
}
.name-detail-header {
  color: var(--primary);
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
