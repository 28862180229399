var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_vm._t("breadcrumbsWrp"),(_vm.viewTabs && _vm.frameType === 'tabs')?_c('v-tabs',{staticClass:"menu-tabs",class:_vm.$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mb-5',attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.viewTabs.tabs),function(tab){return _c('v-tab',{key:tab.name,attrs:{"to":{ name: tab.name },"id":tab.name}},[_vm._v(_vm._s(_vm.traduceItem(tab.name)))])}),1):_vm._e(),(_vm.actualGroup)?_c('div',[_c('router-view'),(_vm.loadingPage && !Array.isArray(_vm.dataComponent))?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',[(
          (_vm.actualView.name !== 'ProcessHistory') || 
          (_vm.processData && _vm.processData.length && _vm.actualView.name === 'ProcessHistory')
        )?_c('div',{ref:"searchActions",staticClass:"d-flex align-center",class:!_vm.actualView.parent && !(_vm.actualView.meta && _vm.actualView.meta.parent)
            ? 'justify-end mb-4'
            : 'justify-space-between'},[(((_vm.actualView.search && _vm.dataView && _vm.dataView.length && _vm.actualView.name !== 'ProcessHistory') || (_vm.processData && _vm.processData.length && _vm.actualView.name === 'ProcessHistory')) && 
          _vm.actualView.search)?_c('SearchEngine',{key:_vm.keyToSearch,staticClass:"flex-grow-1",attrs:{"toSearch":_vm.toSearch,"autofocus":false,"results":_vm.dataComponent?.length,"fieldToSearchWhenWriteOnInput":_vm.toSearch?.fields[0]?.field},on:{"search":_vm.debounceSearch}}):_vm._e(),((_vm.actualView.meta && _vm.actualView.meta.parent && _vm.dataView) || (_vm.actualView.parent && _vm.dataView))?_c('div',{staticClass:"name-detail-header"},[_vm._v(" "+_vm._s(_vm.dataView.name)+" ")]):_vm._e(),(((_vm.actualView.actions && _vm.actualView.actions.length) || (_vm.actualView.parent && _vm.actualView.parent.actions && _vm.actualView.parent.actions.length)) && 
                ((_vm.actualView.name === 'ProcessHistory' && _vm.processData?.length) || (_vm.actualView.name !== 'ProcessHistory')))?_c('Actions',{staticClass:"ml-2 mr-0",attrs:{"type":_vm.typeActions,"actions":_vm.actions,"background":_vm.background,"btnTriggerColor":_vm.colorMenuActions},on:{"action-selected":_vm.onActionSelected}}):_vm._e()],1):_vm._e(),(
          (_vm.dataComponent && Array.isArray(_vm.dataComponent) && _vm.dataComponent.length &&
            (!_vm.actualView.meta || (_vm.actualView.meta && !_vm.actualView.meta.parent))) ||
          (typeof _vm.dataComponent === 'object' && Object.keys(_vm.dataComponent).length && _vm.actualView.meta && _vm.actualView.meta.parent) || 
          (_vm.actualView.parent)
        )?_c(_vm.moduleComponent,{tag:"component",attrs:{"headerHeight":_vm.headerHeight,"dataComponent":_vm.dataComponent},on:{"table-action":_vm.onActionSelected}}):_c('div',[_c('NoData',{attrs:{"text":_vm.configNodata.text,"image":_vm.configNodata.image}})],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }