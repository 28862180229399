import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, arrayUnion, arrayRemove, query, where, onSnapshot, orderBy, documentId, deleteField } from "firebase/firestore";
import i18n from '@/plugins/i18n';
import { mapActions, mapState } from "vuex";
import { tools } from '@/mixins/tools';
import {
  getAuth, signOut, onAuthStateChanged,
  // connectAuthEmulator
} from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import store from '@/store';
import VIEWS from "../config/views.json";


const firebaseConfig = {
  apiKey: window.PROJECT_ENV.API_KEY,
  authDomain: window.PROJECT_ENV.AUTH_DOMAIN,
  projectId: window.PROJECT_ENV.PROJECT_ID,
  storageBucket: window.PROJECT_ENV.STORAGE_BUCKET,
  messagingSenderId: window.PROJECT_ENV.GOOGLE_APP_ID,
  appId: window.PROJECT_ENV.APP_ID,
};
const app = initializeApp(firebaseConfig);
const storage = getStorage();
const db = getFirestore(app);
const auth = getAuth();

if (location.hostname === "localhost") {
  // Point to the Storage emulator running on localhost.
  // connectAuthEmulator(auth, "http://localhost:9099");
}

export const firebaseTools = {
  mixins: [tools],
  computed: {
    ...mapState(["actualView"]),
  },
  methods: {
    ...mapActions(["setShowSnackBar", "setUserLogged", "setRouteVuex"]),
    subscribeOnSnapshot(collectionName, queryActualView, saveData = true) {
      const viewName = tools.methods.collectionStore(collectionName)
      if(!store.state[viewName] || !store.state.unsubscribe[viewName]) {
      let list = [];
      const queryOnSnapshot = queryActualView ? query(collection(db, collectionName), where(queryActualView.atribute, queryActualView.operator, queryActualView.value), where("userGroup", "==", store.state.actualGroup.id),orderBy(queryActualView.orderBy || 'name','asc')) : query(collection(db, collectionName), where("userGroup", "==", store.state.actualGroup.id),orderBy('name','asc'));
      const unsubscribe = onSnapshot(queryOnSnapshot, (querySnapshot) => {
        list = [];
        querySnapshot.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id });
        });
        if (saveData) {
          if (store.state.itemToSelect) {
            let newItem = list.find((element) => element.name === store.state.itemToSelect.name);
            store.dispatch("setItemToSelect", newItem);
          }
          const actualView = store.state.actualView.parent || store.state.actualView;
          store.dispatch(tools.methods.collectionStoreDispatcher(collectionName), list);
          if(
            (!actualView.meta?.parent && !actualView.parent) &&
              (actualView?.get[0]?.collection && tools.methods.collectionStore(actualView?.get[0]?.collection) === viewName)
            ) {
            store.dispatch("setDataView", list);
            }
          }
          else store.dispatch("setVolatileData", list);
          store.dispatch("setLoadingPage", false);
        });
        store.dispatch("setUnsubscribe", { [viewName]: unsubscribe });
      }
    },
    subscribeOnSnapshotById(collectionName, queryActualView = null, documentId) {
      this.unsubscribeElement('Detail');
      this.unsubscribeElement('DetailDictionary');
      if (!queryActualView && typeof documentId === 'string') {
        const parentName = store.state.actualView?.parent?.name || store.state.actualView?.meta?.parent || undefined;
        const queryOnSnapshot = query(doc(db, collectionName, documentId));
        const unsubscribe = onSnapshot(queryOnSnapshot, { includeMetadataChanges: true }, (querySnapshot) => {
          const { userGroup } = querySnapshot.data() || {}

          if (userGroup === store.state.actualGroup.id &&
            (
              (parentName === "OutputDefinitionsDetail" && collectionName === "outputDescriptors") ||
              (parentName === "InputDefinitionsDetail" && collectionName === "inputDescriptors") ||
              parentName === 'Dictionaries'
            )
          ) {
            store.dispatch("setDataView", querySnapshot.data());
            store.dispatch("setLoadingPage", false);
          } else {
            store.dispatch("setDataView", undefined);
            store.dispatch('setRouteVuex', 'Error404');
          }
        });
        store.dispatch("setUnsubscribe", { Detail: unsubscribe, DetailDictionary: unsubscribe });
      }
    },
    subscribeOnSnapshotUser(userId) {
      const queryOnSnapshot = query(doc(db, 'users', userId));
      const unsubscribe = onSnapshot(queryOnSnapshot, (querySnapshot) => {
        store.dispatch('setUserLogged', { ...store.state.userLogged, ...querySnapshot.data() });
        localStorage.setItem('userLogged', JSON.stringify(store.state.userLogged));
        i18n.locale = store.state.userLogged.lang
        this.$vuetify.lang.current = store.state.userLogged.lang;
        this.getUserGroups();
      });
      store.dispatch("setUnsubscribe", { userLogged: unsubscribe });
    },
    getUserGroups() {
      let userGroups = [];
      const queryGroups = query(collection(db, 'groups'), where(documentId(), 'in', store.state.userLogged.groups));
      getDocs(queryGroups)
        .then((querySnapshot) => {
          querySnapshot.forEach((document) => {
            userGroups.push({ id: document.id, name: document.data().name });
          });
          store.dispatch('setUserGroups', userGroups);
          if (!localStorage.getItem('actualGroup') || (localStorage.getItem('actualGroup') && !userGroups.find(group => group.id === JSON.parse(localStorage.getItem('actualGroup')).id))) {
            localStorage.setItem('actualGroup', JSON.stringify(userGroups[0]));
            store.dispatch('setActualGroup', userGroups[0]);
          }
          else if (localStorage.getItem('actualGroup')) {
            store.dispatch('setActualGroup', JSON.parse(localStorage.getItem('actualGroup')));
          }
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },
    subscribeOnSnapshotProcess() {
      let elementModified = {};
      const queryOnSnapshot = query(collection(db, "process"), where("user.group", "==", store.state.actualGroup.id), orderBy("startDate", "desc"), orderBy("inputFileName", "desc"), orderBy("user.name", "desc"));
      const unsubscribe = onSnapshot(queryOnSnapshot, (querySnapshot) => {
        let list = [];
        querySnapshot.docChanges().forEach((change) => {

          if (change.type === "modified") {
            elementModified = { ...change.doc.data(), id: change.doc.id }
            switch (elementModified.status) {
              case "FINISHED":
                this.handleFireBaseRequests("finish-process", null, null)
                break;
              case "FAIL":
                this.handleFireBaseRequests("error-process", null, null)
                break;
            }
          }
        });

        querySnapshot.forEach((doc) => {
          list.push({ ...doc.data(), id: doc.id });
        });
        store.dispatch("setProcessData", list);
        store.dispatch("setLoadingPage", false)
      });
      store.dispatch("setUnsubscribe", { ProcessHistory: unsubscribe });
    },

    getAllData(collectionName, queryActualView = null) {
      let list = [];
      const queryOnSnapshot = queryActualView ? query(collection(db, collectionName), where(queryActualView.atribute, queryActualView.operator, queryActualView.value), where("userGroup", "==", store.state.actualGroup.id)) : query(collection(db, collectionName), where("userGroup", "==", store.state.actualGroup.id));
      return getDocs(queryOnSnapshot)
        .then((querySnapshot) => {
          list = [];
          querySnapshot.forEach((doc) => {
            list.push({ ...doc.data(), id: doc.id });
          });
          return list;
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },

    getDataByDocumentId(collectionName, documentId) {
      return getDoc(doc(db, collectionName, documentId))
        .then((querySnapshot) => {
          return querySnapshot;
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },

    insertDocument(collectionName, object, nullableFields = []) {
      store.dispatch("setItemToAddEdit", undefined);
      store.dispatch("setItemToAddEditStepper", undefined);
      function deleteNulls(obj) {
        Object.keys(obj).forEach(el => {
          const objEl = obj[el]
          const deleteable = Boolean(!nullableFields.includes(el) && ([null, undefined, ''].includes(objEl) || Array.isArray(objEl) && !objEl.length) || (typeof objEl === 'object' && !Object.keys.length))

          if (typeof objEl === 'object' && !Array.isArray(obj) && !!objEl) deleteNulls(objEl)
          else if (deleteable) delete obj[el]
        })
      }
      deleteNulls(object)

      return addDoc(collection(db, collectionName), object)
        .then((querySnapshot) => {
          collectionName === 'process'
            ? this.handleFireBaseRequests("success-process", object.name, 'add')
            : this.handleFireBaseRequests("success-CRUD", object.name, 'add')
          return querySnapshot;
        })
        .catch(() => {
          this.handleFireBaseRequests("error-CRUD", object.name, 'add');
        });
    },
    updateDocument(collectionName, documentId, object = {}, actionSetted = undefined, nameNotification = null, deleteNullValuesParam = false) {
      store.dispatch("setLoadingData", true)
      if (!nameNotification) nameNotification = object?.nameNotification
      delete object?.nameNotification
      let values = object;

      let itemToAddEdit = store.state.itemToAddEdit || store.state.itemToAddEditStepper || store.state.itemToConfirmAction;
      const { params, deleteNullValues = deleteNullValuesParam, nullableFields = [], isLastItemToDelete = false } = itemToAddEdit?.data || {};
      const { msgAction = undefined, updateValuesFB = undefined, nameToNotify = undefined } = params || {};
      let action = actionSetted || msgAction || 'edit';

      const { operator = null, atribute = null, rawUpdate, parentAttr } = updateValuesFB || {};

      function deleteNulls(obj) {
        Object.keys(obj).forEach(el => {
          const objEl = obj[el]
          const deleteable = Boolean(!nullableFields.includes(el) && ([null, undefined, ''].includes(objEl) || Array.isArray(objEl) && !objEl.length) || (typeof objEl === 'object' && !Object.keys.length))

          if (typeof objEl === 'object' && !Array.isArray(obj) && !!objEl) deleteNulls(objEl)
          if (deleteable && updateValuesFB) delete obj[el]
          else if (deleteable && !updateValuesFB) obj[el] = deleteField()
        })
      }

      if (deleteNullValues) deleteNulls(updateValuesFB ? object : values)
      if (updateValuesFB) {
        if (deleteNullValues) deleteNulls(object)
        if (operator === 'deleteField' || isLastItemToDelete) { 
          values = { [parentAttr || atribute]: deleteField() }
        } else if (operator === 'arrayUnion' || operator === 'both') {
          values = { [atribute]: arrayUnion(object) }
        } else if (operator === 'arrayRemove' && !rawUpdate) {
          values = { [atribute]: arrayRemove(object) }
        } else if (['nestedArrayAdd', 'nestedArrayEdit'].includes(operator)) {
          let dataToSet = { [parentAttr || atribute]: object }
          values = { ...dataToSet }
        }
      }


      store.dispatch("setItemToConfirmAction", undefined);
      store.dispatch("setItemToAddEdit", undefined);
      store.dispatch("setItemToAddEditStepper", undefined);

      const documentRef = doc(db, collectionName, documentId);
      const objectToReturn = nameNotification || nameToNotify || object.name || object.alias 
      const code = collectionName === 'process' ? 'success-process' : 'success-CRUD'

      return updateDoc(documentRef, values)
        .then((querySnapshot) => {
          if (operator === 'both') {
            return updateDoc(documentRef, { [atribute]: arrayRemove(itemToAddEdit.data.params.oldItem) })
              .then((querySnapshot) => {
                this.handleFireBaseRequests(code, objectToReturn, action);
                return querySnapshot;
              })
              .catch(() => {
                this.handleFireBaseRequests("error-CRUD", objectToReturn, action);
              });
          } else {
            this.handleFireBaseRequests(code, objectToReturn, action);
            return querySnapshot;
          }
        })
        .catch(() => {
          this.handleFireBaseRequests("error-CRUD", objectToReturn, action);
        })
        .finally(() => {
          store.dispatch("setLoadingData", false)
        })
    },
    deleteDocument(collectionName, documentId, name) {
      const actualView = store.state.actualView.parent || store.state.actualView
      store.dispatch("setItemToConfirmAction", undefined);
      if (['OutputDefinitionsDetail', 'InputDefinitionsDetail'].includes(actualView.name)) this.unsubscribeElement('Detail');
      if (['DictionariesDetail'].includes(actualView.name)) this.unsubscribeElement('DetailDictionary');
      deleteDoc(doc(db, collectionName, documentId))
        .then((querySnapshot) => {
          this.handleFireBaseRequests("success-CRUD", name, 'delete');
          if (actualView.meta && actualView.meta.parent) {
            store.dispatch('setRouteVuex', actualView.meta.parent)
          }
          return querySnapshot;
        })
        .catch(() => {
          this.handleFireBaseRequests("error-CRUD", name, 'delete');
        });
    },
    unsubscribeElement(element) {
      if (store.state.unsubscribe[element]) {
        store.state.unsubscribe[element]()
        store.dispatch("setUnsubscribe", { [element]: undefined })
      }
    },
    createStorageReference(name) {
      return ref(storage, name);
    },
    logOut() {
      signOut(auth).then(() => {
        Object.keys(store.state.unsubscribe).forEach(element => {
          this.unsubscribeElement(element)
        })
        this.setRouteVuex('Logout');
      }).catch((error) => {
        this.handleFireBaseRequests(error.code)
      });
    },
    handleInvalidJWT() {
      store.dispatch('setUserLogged', undefined);
      localStorage.removeItem('userLogged');
      localStorage.removeItem('token');
      
      signOut(auth).then(() => {
        store.dispatch('setRouteVuex','Login');
        tools.methods.executeAction(
          {
            action: 'cancel',
            confirm: true 
          }
        )
      }).catch((error) => {
        this.handleFireBaseRequests(error.code)
      });
    },
    subscribeIsUserLogged() {
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          this.$router.history.current.name !== 'Login' && this.$router.history.current.name !== 'Logout' && store.dispatch('setRouteVuex', "Login");
          this.setUserLogged(undefined);
          localStorage.removeItem('userLogged');
          localStorage.removeItem('token');
          localStorage.removeItem('actualGroup');
          store.dispatch("setActualGroup", undefined);
          store.dispatch("setDataView", undefined);
          store.dispatch("setProcessData", undefined);
        }
        else {
          store.dispatch("setLoadingPage", true);
          if (this.$router.history.current.name === 'Login') store.dispatch('setRouteVuex', "ProcessHistory");
          store.dispatch('setUserLogged', { ...store.state.userLogged, ...user });
          this.subscribeOnSnapshotUser(user.uid);
        }
      });
    },
    suscribeToAllViews() {
      Object.keys(store.state.unsubscribe).forEach(element => {
        if (element !== 'userLogged' && element !== 'Detail' && element !== 'DetailDictionary') {
          this.unsubscribeElement(element)
        }
      })

      VIEWS.viewsScheme.forEach(({ get, meta, name }) => {
        if (!meta?.parent && name !== "ProcessHistory") {
          get.forEach((item) => {
            firebaseTools.methods[item.get](item.collection, item.query || null, true);
          });
        }
      });
    },
    handleFireBaseRequests(code, element = null, action = null) {
      const actualView = store.state.actualView.parent || store.state.actualView
      switch (code) {
        case 'auth/user-not-found':
          return i18n.t('loginUserError');
        case 'auth/wrong-password':
          return i18n.t('loginPswdError');
        case 'auth/invalid-email':
          return i18n.t('loginEmailError');
        case 'permission-denied':
          store.dispatch('setRouteVuex', 'NotAllowed');
          break;
        case 'error-CRUD':
          store.dispatch("setShowSnackBar", { color: 'error', icon: "mdi-alert-circle", msg: i18n.t(`error.${action}.${actualView.name}`, { element: element }) });
          break;
        case 'success-CRUD':
          store.dispatch("setShowSnackBar", { color: 'success', icon: "mdi-check-circle", msg: i18n.t(`success.${action}.${actualView.name}`, { element: element }) });
          break;
        case 'success-process':
          if (action === 'edit') return;
          if (actualView.name === "ProcessHistory")
            store.dispatch("setShowSnackBar", { color: 'info', icon: "mdi-information", msg: i18n.t('runningProcess') });
          else
            store.dispatch("setShowSnackBar", { color: 'info', icon: "mdi-information", msg: i18n.t('runningProcess'), link: { to: 'ProcessHistory', text: i18n.t('seeProcessStatus') } });
          break;
        case 'auth/internal-error':
          store.dispatch("setShowSnackBar", { color: 'error', icon: "mdi-alert-circle", msg: i18n.t(`error.emailBlocked`) });
          break;
        case 'finish-process':
          if (actualView.name === "ProcessHistory")
            store.dispatch("setShowSnackBar", { color: 'success', icon: "mdi-check-circle", msg: i18n.t('finishProcess') });
          else
            store.dispatch("setShowSnackBar", { color: 'success', icon: "mdi-check-circle", msg: i18n.t('finishProcess'), link: { to: 'ProcessHistory', text: i18n.t('seeFileProcesses') } });
          break;
        case 'error-process':
          if (actualView.name === "ProcessHistory")
            store.dispatch("setShowSnackBar", { color: 'error', icon: "mdi-alert-circle", msg: i18n.t(`error.add.ProcessHistory`) });
          else
            store.dispatch("setShowSnackBar", { color: 'error', icon: "mdi-alert-circle", msg: i18n.t(`error.add.ProcessHistory`), link: { to: 'ProcessHistory', text: i18n.t('seeFileProcesses') } });
          break;
        default:
          store.dispatch("setShowSnackBar", { color: 'error', icon: "mdi-alert-circle", msg: i18n.t('unexpectedError') });
          break;
      }
    }
  },
}
