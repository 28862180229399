<template>
  <div class="import-file">
    <ImportSourceSelector
      :wrpClasses="wrpClasses"
      :color="'primary'"
      :selectedSource="selectedImportSource"
      :googleDeveloperKey="googleDeveloperKey"
      :googleClientId="googleClientId"
      :googleAppId="googleAppId"
      @sourceSelected="changedSelectedImportSource"
      @fileSelectedDrive="fileSelectedDrive"
    />     
    <template v-if="errorFileImport">
      <v-alert
        outlined
        :type="'error'"
        :icon="'mdi-alert-circle'"
        color="error"
        class="mt-5"
        >{{ $t(errorFileImport) }}
      </v-alert>
    </template>
    <template>
      <Import
        v-if="selectedImportSource === 'yourPc'"
        class="importFileArea mt-5"
        :title="$t('importFileFromYourPc')"
        :subtitle="$t('clickOrDragToImport')"
        :icon="'mdi-laptop'"
        @file="fileSelected"
        :color="'primary'"
      />
      <FileToUpload
        v-if="selectedFile"
        :file="selectedFile"
        :wrpClasses="wrpClasses"
        @clearFile="clearFile"
        class="mt-5"
      />
    </template>
  </div>
</template>

<script>
import ImportSourceSelector from "@/components/ImportSourceSelector.vue";
import FileToUpload from "@/components/FileToUpload.vue";
import i18n from "@/plugins/i18n";

export default {
  name: "ImportFile",
  components: {
    ImportSourceSelector,
    FileToUpload,
  },
  props: {
    submitFormComponent: { type: Boolean },
    currentDataValues: { type: Object },
    resetComponent: { type: Boolean },
    config: { type: Object ,default: () => ({ allowedExtensionFiles: ['csv', 'xlsx'] })},
  },
  data: () => ({
    googleAppId: window.PROJECT_ENV.GOOGLE_APP_ID,
    googleClientId: window.PROJECT_ENV.GOOGLE_CLIENT_ID,
    googleDeveloperKey: window.PROJECT_ENV.API_KEY,
    selectedImportSource: undefined,
    selectedFile: undefined,
    errorFileImport: undefined,
    loading: false,
  }),
  created() {
    const { selectedFile, selectedFrom } = this.currentDataValues || {};
    this.selectedFile = selectedFile;
    this.selectedImportSource = selectedFrom;
  },
  computed: {
    wrpClasses() {
      return {
        medium: true,
      };
    },
    allowedExtensionFilesRegexp() {
      const { allowedExtensionFiles } = this.config;    
      return new RegExp(`[^s]+(.*?).(${allowedExtensionFiles.join('|')})$`)
    },
  },
  watch: {
    submitFormComponent(val) {
      if (val && !this.errorFileImport) {
        if (!this.selectedImportSource) {
          this.errorFileImport = "youMustSelectAnImportOption";
        } else if (!this.selectedFile) {
          this.errorFileImport = "youMustSelectAtLeastOneFileToImport";
        } else {
          this.errorFileImport = undefined;
        }
      }
    },
    errorFileImport(val) {
      if (val) {
        this.$emit('isValid', false);
      } else {
        this.$emit('isValid', true);
      }
    },
    selectedFile(val) {
      this.$emit('isValid', Boolean(val && !this.errorFileImport));
      this.$emit('update', val ? { selectedFile: val, selectedFrom: this.selectedImportSource} : null);
    },
    resetComponent(val) {
      if (val) {
        this.selectedImportSource = undefined;
        this.selectedFile = undefined;
        this.errorFileImport = undefined;
        this.clearInnerInput();
      }
    }
  },
  methods: {
    changedSelectedImportSource(selectedSource) {
      this.$emit('dirty', true);
      this.selectedImportSource = selectedSource;
      this.selectedFile = undefined;
      this.errorFileImport = undefined;
    },
    isValidExtensionFile(name) {
      const valid = this.allowedExtensionFilesRegexp.test(name);
      if (!valid) this.clearInnerInput();
      this.errorFileImport = !valid ? i18n.t("extensionErrorFile", {element: this.config?.allowedExtensionFiles.join(` ${i18n.t('or')} .`)}) : undefined;
      return valid;
    },
    fileSelected(event) {
      const { name } = event
      this.selectedFile = event && this.isValidExtensionFile(name) ? event : undefined;
    },
    async fileSelectedDrive({ fileData, oauthToken }) {
      this.loading = true;
      const fetchOptions = {
        headers: { Authorization: `Bearer ${oauthToken}` },
      };
      const { id, name, mimeType, lastEditedUtc, size } = fileData;
      const blob = await fetch(
        `https://www.googleapis.com/drive/v3/files/${id}?alt=media`,
        fetchOptions
      ).then((res) => {
        this.loading = false;
        return res.blob();
      });
      const fileOptions = {
        type: mimeType,
        lastModified: lastEditedUtc,
        size,
      };

      this.selectedFile = blob && this.isValidExtensionFile(name) ? new File([blob], name, fileOptions) : undefined;
    },
    clearFile() {
      if (this.selectedImportSource === "drive") this.selectedImportSource = undefined;
      this.selectedFile = undefined;
      this.clearInnerInput();
    },
    clearInnerInput() {
      const inputFile = document.querySelector(".import-file input[type='file']");
      if (inputFile) inputFile.value = "";
    },
  },
};
</script>

<style scoped lang="scss">
.v-alert {
  font-size: 16px;
}
.importFileArea {
  color: var(--fontColorTerciary);
  min-height: 200px;
  ::v-deep {
    .dropArea {
      p {
        margin: 0;
      }
      padding: 30px 85px;
    }
    .dropArea i {
      height: 100%;
      color: var(--primary);
    }
    .dragFile {
      font-weight: 400;
    }
    .clickImport {
      font-weight: 500;
    }
  }
}
</style>
