<template>
  <div>
    <v-dialog :value="dictionaryToImport" :width="900" v-click-outside="cancel" content-class="dialog containerStepper">
      <v-card>
        <v-card-title v-if="dictionaryToImport && dictionaryToImport.config && dictionaryToImport.config.title">
          <div>{{ dictionaryToImport.config.title }}</div>
          <div>
            <v-btn rounded outlined v-if="dictionaryToImport.config.downloadButton && currentStep === 1"
              @click="downloadDictionaryTemplate()">
              <v-icon left>{{ dictionaryToImport.config.downloadButton.icon }}</v-icon>
              {{ dictionaryToImport.config.downloadButton.text }}
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text class="text-content">
          <div class="d-flex contenedor" v-if="dictionaryToImport && steps && steps.length">
            <div class="mr-1 left-stepper" min-height="380">
              <v-stepper v-model="currentStep" vertical flat>
                <div v-for="(step, index) in steps" :key="index" class="step-item">
                  <v-stepper-step :step="index + 1" :style="{ cursor: 'pointer' }">
                      {{ step.title }}
                  </v-stepper-step>
                </div>
              </v-stepper>
            </div>

            <v-divider vertical></v-divider>

            <div class="ml-1 right-stepper">
              <v-stepper :v-model="currentStep" vertical flat :style="{ overflow: 'auto', 'padding-right': '5px' }">
                <v-stepper-step :complete="false" :step="currentStep" class="ml-2">
                  {{ steps[currentStep - 1].title }}
                </v-stepper-step>
                <v-stepper-content :step="currentStep">
                  <div ref="importWrp">
                    <ImportSourceSelector class="pl-4" :wrpClasses="wrpClasses" color="var(--primary)"
                      :selectedSource="selectedImportSource" :googleDeveloperKey="googleDeveloperKey"
                      :googleClientId="googleClientId" :googleAppId="googleAppId"
                      @sourceSelected="changedSelectedImportSource" @fileSelectedDrive="fileSelectedDrive" />
                    <v-alert v-if="errorFileImport || showAlert" outlined type="error" :icon="'mdi-alert-circle'"
                    class="ml-5 mt-5 import-alert">
                      {{ errorFileImport || showAlert }}
                    </v-alert>
                    <div class="import-wrp">
                      <Import v-if="selectedImportSource === 'yourPc'" class="importFileArea"
                        :title="traduceItem('importFileFromYourPc')" :subtitle="traduceItem('clickOrDragToImport')"
                        :icon="'mdi-laptop'" @file="fileSelected" />
                      <FileToUpload v-if="selectedFile" :file="selectedFile" :wrpClasses="wrpClasses"
                        @clearFile="clearFile" class="clearFileContainer" />
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper>
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="dictionaryToImport">
          <v-btn rounded outlined v-if="dictionaryToImport.config.secondaryButton && currentStep === 1"
            @click="cancel('buttonCancel')">
            <v-icon left>{{ dictionaryToImport.config.secondaryButton.icon }}</v-icon>
            {{ dictionaryToImport.config.secondaryButton.text }}
          </v-btn>
          <v-btn rounded outlined v-else @click="selectStep(currentStep - 1)">
            <v-icon left>mdi-chevron-left</v-icon>
            {{ traduceItem("back") }}
          </v-btn>
          <v-btn @click="currentStep === steps.length ? submit() : selectStep(currentStep + 1)" color="primary" rounded
            depressed v-if="dictionaryToImport.config.primaryButton">
            <template v-if="currentStep === steps.length">
              <v-icon left>{{ dictionaryToImport.config.primaryButton.icon }}</v-icon>
              {{ dictionaryToImport.config.primaryButton.text }}
            </template>
            <template v-else>
              {{ traduceItem("continue") }}
              <v-icon right>mdi-chevron-right</v-icon>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileToUpload from "@/components/FileToUpload.vue";
import ImportSourceSelector from "@/components/ImportSourceSelector.vue";
import { firebaseTools } from "@/mixins/firebase-tools";
import { tools } from "@/mixins/tools";
import i18n from "@/plugins/i18n";
import { getDownloadURL } from "firebase/storage";
import { mapActions, mapState } from "vuex";

export default {
  name: "ImportDictionariesModal",
  components: { ImportSourceSelector, FileToUpload },
  mixins: [tools, firebaseTools],
  data: () => ({
    googleAppId: window.PROJECT_ENV.GOOGLE_APP_ID,
    googleClientId: window.PROJECT_ENV.GOOGLE_CLIENT_ID,
    googleDeveloperKey: window.PROJECT_ENV.API_KEY,
    selectedImportSource: null,
    selectedFile: null,
    errorFileImport: null,
    showAlert: undefined,
    currentStep: 1,
    alerts: {},
    steps: [
      {
        title: i18n.t("selectFile"),
        resume: {
          from: "currentDataValues",
          fields: [
            {
              label: i18n.t("fileType"),
              value: "fileType",
              lowerCase: true,
            },
          ],
        },
      },
    ],
  }),
  computed: {
    ...mapState(["dictionaryToImport", "envTheme", "userLogged", "loadingData", "actualGroup"]),
    currentDataValues() {
      return this.dictionaryToImport?.currentDataValues || null;
    },
    wrpClasses() {
      const importWrpWidth =
        this.isIntersecting && this.modal && this.$refs && this.$refs.importWrp && this.$refs.importWrp.offsetWidth;
      const vuetifyBreakpoint = this.$vuetify.breakpoint.width;
      const parentWidth = importWrpWidth || vuetifyBreakpoint;

      return {
        small: parentWidth < 499,
        medium: parentWidth >= 500 && parentWidth < 960,
        large: parentWidth >= 960 && parentWidth < 1253,
        xLarge: parentWidth >= 1253,
      };
    },
  },
  methods: {
    ...mapActions(["setItemToImportDictionary", "setShowSnackBar", "setLoadingData"]),
    cancel(event) {
      if (
        !((event.target && event.target.classList.contains("v-overlay__scrim")) || event === "buttonCancel") ||
        !this.dictionaryToImport
      )
        return;
      this.showAlert = null;
      this.executeAction({
        action: "cancel",
        section: "ImportDictionary"
      });
    },
    changedSelectedImportSource(selectedSource) {
      this.selectedImportSource = selectedSource;
      this.selectedFile = null;
      this.errorFileImport = null;
      this.showAlert = null;
    },
    async fileSelectedDrive({ fileData, oauthToken }) {
      this.loading = true;
      const fetchOptions = {
        headers: { Authorization: `Bearer ${oauthToken}` },
      };
      const blob = await fetch(`https://www.googleapis.com/drive/v3/files/${fileData.id}?alt=media`, fetchOptions).then(
        (res) => {
          this.loading = false;
          return res.blob();
        },
      );
      const fileOptions = {
        type: fileData.mimeType,
        lastModified: fileData.lastEditedUtc,
        size: fileData.size,
      };

      this.selectedFile = new File([blob], fileData.name, fileOptions);
    },
    fileSelected(event) {
      if (event) {
        this.errorFileImport = null;
        this.selectedFile = event;
      }
    },
    clearFile() {
      if (this.selectedImportSource === "drive") this.selectedImportSource = null;
      this.selectedFile = null;
      const inputFile = document.querySelector(".import-wrp input[type='file']");
      if (inputFile) inputFile.value = "";
    },
    submit() {
      if (!this.selectedImportSource) this.showAlert = i18n.t("youMustSelectAnImportOption");
      else if (!this.selectedFile) this.showAlert = i18n.t("youMustSelectAtLeastOneFileToImport");

      if (!Object.keys(this.alerts).some((el) => this.alerts[el].some((el) => el === true)) && this.selectedFile) {
        let params = new FormData();
        if (this.dictionaryToImport && this.dictionaryToImport.config && this.dictionaryToImport.config.data && this.dictionaryToImport.config.data.dictionaryId) {
          params.append("dictionaryId", this.dictionaryToImport.config.data.dictionaryId);
        }
        params.append("lang", this.userLogged.lang);
        params.append("file", this.selectedFile);
        params.append("userGroup", this.actualGroup?.id);
        let selectedFile = global.structuredClone(this.selectedFile);
        this.setShowSnackBar({
          color: "info",
          icon: "mdi-information",
          msg: i18n.t("uploadedImport", { element: selectedFile.name }),
        });
        this.setLoadingData(true);
        this.httpRequest("post", [window.PROJECT_ENV.BASE_URL_SENDER + "import/dictionary", params], false)
          .then(async (res) => {
            if (res) {
              await this.setShowSnackBar(false)
              const error = res.level === "FAIL";
              this.setShowSnackBar({
                color: error ? "error" : "success",
                icon: error ? 'mdi-alert-circle' : "mdi-check-circle",
                msg: error ? res.msg : i18n.t("successfulImport", { element: selectedFile.name }),
              });
              if (error) this.setLoadingData(false)
            } else {
              this.setLoadingData(false);
              this.loading = false;
            }
          })
          .catch((error) => {
            this.setLoadingData(false);
            this.handleHttpStatus(error);
            this.loading = false;
          })
        this.setItemToImportDictionary();
      }
    },
    downloadDictionaryTemplate() {
      this.setShowSnackBar({
        color: 'info',
        msg: i18n.t('downloadingFile'),
        icon: "mdi-information",
      })
      this.loading = true;
      const fileRef = this.createStorageReference('templates/trafis_dictionary_template.csv');
      // Get the download URL
      getDownloadURL(fileRef)
        .then((url) => {
          this.downloadFile(
            url,
            "get",
            'trafis_dictionary_template.csv',
            null
          ).then(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.setShowSnackBar({
            color: 'error',
            msg: i18n.t('errorDownloadFile'),
            icon: "mdi-alert-circle",
          })
          this.loading = false;
        });
    },
  },
  watch: {
    selectedFile() {
      this.showAlert = null;
      if (
        this.selectedFile &&
        this.selectedFile.name.split(".").pop().toUpperCase() !== "CSV"
      ) {
        this.errorFileImport = i18n.t("extensionErrorFile", { element: "csv" });
        this.selectedFile = null;
      } else if (this.selectedFile) this.errorFileImport = null;
    },
    dictionaryToImport() {
      this.selectedImportSource = null;
      this.selectedFile = null;
      this.errorFileImport = null;
      this.currentStep = 1;
      this.alerts = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  ::v-deep {
    .containerStepper {
      border-radius: 20px;
      min-height: 96vh;
    }
  }

  .text-content {
    height: calc(95vh - 135px);
  }

  .v-card__actions {
    padding: 20px 16px;
  }
}

.left-stepper {
  width: 45%;
  height: calc(95vh - 135px);

  .v-stepper__step {
    padding-left: 20px;
    align-items: baseline;
  }

  .v-stepper__step--complete {
    .resumeStep {
      font-size: 14px;
      margin-bottom: -31px;
      color: var(--fontColor);
      margin-left: -26px !important;
      margin-top: 10px;
      border-left: 1px solid var(--borderGray) !important;
      min-height: 30px;
      padding: 25px 50px 15px 23px;

      span {
        color: var(--fontColorTerciary);
      }

      .itemResumeStep:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .step-item:not(:last-child) {
    ::v-deep {
      .v-stepper__content {
        margin-left: 10px !important;
        border-left: 1px solid var(--borderGray) !important;
        min-height: 40px;
      }
    }
  }
}


.v-stepper--vertical .v-stepper__step {
  padding: 5px 16px 5px 0px;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: 0;
  padding: 0;
}

.contenedor {
  width: 100%;
}

.v-card__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.import-alert {
  width: calc(100% - 20px);
  font-size: 16px;
}

::v-deep {
  .importFileArea+.component-container {
    margin-top: 20px;
  }

  .import-wrp {
    margin: 20px 0 20px 20px;

    .dragFile {
      font-weight: 400;
    }

    .clickImport {
      color: var(--fontColorTerciary);
      font-weight: 400;
    }

    .mdi-laptop {
      color: var(--primary);
      margin: 27px 0 6px 0;
    }

    .dropArea {
      padding: 0 60px;
    }
  }

  .v-stepper .v-stepper__step--active .v-stepper__label{
    font-size: 16px;
    color: var(--font);
    text-shadow: none;
    font-weight: 500;
  }
  .v-card__text.text-content {
    padding: 0 20px 20px
  }
}

.right-stepper {
  width: 54%;

  .v-stepper--vertical .v-stepper__step {
    padding: 0px 16px;
    padding-left: 8px;
  }
}
</style>
