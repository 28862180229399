<template>
  <div>
    <section class="title-wrp">
      {{ title }}
      <section>
        <v-icon
          :color="'primary'"
          :size="30"
          tag="button"
          type="button"
          :disabled="!isValidForm"
          @click.stop="addCase()"
        >
          mdi-plus-circle
        </v-icon>
      </section>
    </section>
    <section>
      <v-list v-if="items" active-class="active-case">
        <template v-for="(item, index) in items.length">
          <v-list-item
            :key="item.title"
            @click.stop.prevent="changeACase(index)"
            :active-class="markAsInvalid ? 'active-case error--text' : 'active-case'"
            :input-value="index === selectedCase"
            :value="index"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon left v-if="index === selectedCase && markAsInvalid" v-text="'mdi-alert-circle'" :color="'error'" size="18"></v-icon>
                <span :class="{'error--text': index === selectedCase && markAsInvalid}">
                  {{`${$t('case')} ${item}`}}
                </span>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon v-text="'mdi-delete'" color="primary" @click.stop="deleteACase(index)" :disabled="items.length <= 1"></v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </section>
  </div>
</template>

<script>
import { tools } from '@/mixins/tools.js'

export default {
  name: 'CasesList',
  mixins: [tools],
  props: {
    title: { type: String, default: 'Cases List' },
    selectedCase: { type: Number, default: 0, required: true },
   currentDataValues: { type: Object },
    formAttribute: { type: String, default: 'conditionals'},
    isValidForm: { type: Boolean },
    submitFormComponent: { type: Boolean }
  },
  data: () => ({
    markAsInvalid: false,
  }),
  computed: {
    items () {
      return this.currentDataValues && this.currentDataValues[this.formAttribute] || []
    }
  },
  watch: {
    submitFormComponent (val) {
      if (val && !this.isValidForm) {
        this.markAsInvalid = true
      }
    },
    isValidForm (val) {
      if (val && this.markAsInvalid) {
        this.markAsInvalid = false
      }
    }
  },
  methods: {
    addCase() {
      let data = this.deepCopyFunction(this.currentDataValues)
      const index = data && data[this.formAttribute] && data[this.formAttribute].length
      this.$set(data[this.formAttribute], index, {})
      this.$emit('changeCase', index)
      this.$emit('update', data)
      this.$emit('dirty', true)
    },
    deleteACase(e) {
      let data = this.deepCopyFunction(this.currentDataValues)
      this.$delete(data[this.formAttribute], e)
      this.$emit('changeCase', data[this.formAttribute].length > this.selectedCase ?  this.selectedCase : this.selectedCase - 1 )
      this.$emit('update', data)
      this.$emit('dirty', true)
    },
    changeACase(e) {
      if (!this.isValidForm) {
        this.$emit('changeCase', this.selectedCase);
        return
      }
      this.$emit('changeCase', e)
    }
  },
  beforeDestroy() {
    this.$emit('changeCase', 0)
  }
}
</script>

<style lang="scss" scoped>
.title-wrp {
  color: var(--fontColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.v-list {
  .v-list-item {
    padding-right: 0;
    margin-right: 4px;
    padding-left: 10px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--borderGray); 
    }
    &.active-case {
      color: var(--primary);
      background-color: #0000000d;
    }
    &:not(.active-case) {
      color: var(--fontColor) !important;
    }
    .v-list-item__title {
      display: flex;
      align-items: center;
    }
  }
}
</style>