<template>
  <section class="resume-wrapper" v-if="elements.length">
    <template v-for="(item, index) in elements">
      <template v-if="item.itemProp">
        <div v-for="(el, i) in item.values" :key="i">
          <span class="resume-key">
            {{ el.label }}
          </span>
          <span>
            {{ el.value }}
          </span>
        </div>
      </template>
      <template v-else class="gap-5">
        <div :key="index" class="d-flex flex-wrap gap-5">
          <span class="resume-key">
            {{ item.label }}
          </span>
          <div class="d-flex flex-wrap gap-10">
            <span v-if="!item.values.length && item.showWhenNull">{{ item.showWhenNull }}</span>
            <template v-else-if="item.chips">
              <v-chip v-for="(value, i) in item.values" :key="i" class="chip-item" small color="#DDD">
                <span class="chip-text">
                  {{ value }}
                </span>
              </v-chip>

            </template>
            <template v-else>
              <span v-for="(value, i) in item.values" :key="i">
                {{ value }}
              </span>
            </template>
          </div>
        </div>
      </template>
    </template>
  </section>
</template>

<script>

export default {
  name: "StepsResume",
  props: {
    items: { type: Array },
    currentValues: { type: Object },
  },
  data() {
    return {
      elements: undefined
    };
  },
  created() {
    let elements = []

    if (this.items?.length) {
      for (const item of this.items) {

        const { valuekey, key, translateValue, itemProp, label, chips, showWhenNull } = item || {}

        let itemToPush = { label, itemProp, chips, showWhenNull }

        const value = key ? this.currentValues[key] : this.currentValues

        if (value) {
          if (Array.isArray(value)) itemToPush.values = value.map((v) => translateValue ? this.$t(v[valuekey]) : v[valuekey])
          else if(typeof value[valuekey] === 'boolean') itemToPush.values = this.$t(value[valuekey] === true ? 'yes' : 'no')
          else itemToPush.values = (translateValue ? this.$t(value[valuekey]) : value[valuekey]) || null
        
          if (itemProp) itemToPush.values = itemToPush.values.map((v, i) => ({ value: v[itemProp], label: `${label} ${i + 1}` }))

        }

        if (!Array.isArray(itemToPush.values)) itemToPush.values = [itemToPush?.values]
        itemToPush.values = itemToPush.values.filter((v) => v !== undefined && v !== null)
        if (itemToPush.values?.length) elements.push(itemToPush)
      }
    }
    this.elements = elements
  },
};
</script>

<style lang="scss" scoped>
.resume-wrapper {
  padding: 20px;
  color: var(--fontColor);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}


.resume-key {
  color: var(--fontColorTerciary);
}

.chip-text {
  color: var(--gray);
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>