import store from '@/store'
import i18n from "@/plugins/i18n";
import { firebaseTools } from "@/mixins/firebase-tools"
import axios from "axios"
import datasource from "@/mixins/datasource-fields";

export const tools = {
  methods: {
    navigateTo(name) {
      function isParent(view, viewName) {
        if (view && !view.parent) {
          return false;
        } else if (view.parent.name === viewName) {
          return true;
        } else {
          return isParent(view.parent, viewName)
        }
      }
      this.$router.history.current.name !== name && (!store.state.actualView || !isParent(store.state.actualView, name)) && this.$router.push({ name });
    },
    httpRequest(method, options, notify, returnHttpResponse = false) {
      return axios[method](...options)
        .then(response => {
          if (response.data.level === "SUCCESS" && !notify)
            return returnHttpResponse ? response : response.data;
          else if (response.data.level === "SUCCESS" && notify) {
            this.handleHttpStatus(response);
            return returnHttpResponse ? response : response.data;
          } else if (["VALIDATION_ERROR", "VALIDATION_WARNING", "FAIL"].includes(response.data.level)) {
            return returnHttpResponse ? response : response.data;
          } else this.handleHttpStatus(response);
        })
        .catch(error => this.handleHttpStatus(error))        
    }, 
    downloadFile(url, method, file, params) {
      return axios({
        url,
        method,
        data:params,       
        responseType: "blob"
      })
      .then(response => {
          if (response) {                    
            const contentDisposition = response.headers["content-disposition"] || "";
            const matches = /filename=([^;]+)/gi.exec(contentDisposition);
            const fileName = file ? file : ( matches && matches[1] || "untitled").trim();
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.download = fileName;
            link.click();
            return response
          }
          else this.handleHttpStatus(response);
      }).catch(async error => {
        if (error.response) {        
          const errorResponse = { ...error, response: { ...error.response } };           
          this.handleHttpStatus(errorResponse);
        } else {
           this.handleHttpStatus(error);
        }
      })
    },
    handleHttpStatus(httpResponse, action = null) {    
      if (httpResponse && httpResponse.response) {        
        let statusConfig = {
          msg: i18n.t('unexpectedError'),
          color: 'error',
          icon: 'mdi-alert-circle'
        };
       
        if (httpResponse.response.status === 403) { 
          store.dispatch('setHttpStatus', { ...statusConfig, msg: i18n.t('expiredSession') });
          firebaseTools.methods.handleInvalidJWT();
        } else if (httpResponse.response.data && httpResponse.response.data.msg)
          store.dispatch('setHttpStatus', { ...statusConfig, msg: httpResponse.data.msg });
        else if (httpResponse.response.level === 'FAIL' && httpResponse.response.msg)
          store.dispatch('setHttpStatus', { ...statusConfig, msg: httpResponse.msg });
        else
          store.dispatch('setHttpStatus', { ...statusConfig });       
        
      } else if (httpResponse && httpResponse.status && httpResponse.data.msg) {
        if (httpResponse.data.level === 'VALIDATION_WARNING') {
          let actualWarningMessages = [...store.state.warningMessages]
          actualWarningMessages.push({
            msg: httpResponse.data.msg,
            color: "warning",
            icon: "mdi-alert",
            fileError: httpResponse.data.fileError
          });
          store.dispatch("setWarningMessages", actualWarningMessages);
        } else {
          store.dispatch("setHttpStatus", {
            msg: httpResponse.data.msg,
            color: httpResponse.data.level === "SUCCESS" ? "success" : "error",
            icon: httpResponse.data.level === "SUCCESS" ? "mdi-check-circle" : "mdi-alert-circle",
          });
        }
      } else if(action) {
        const SUCCESS = httpResponse.level === "SUCCESS"
        const code = SUCCESS ? 'success' : 'error'
        store.dispatch("setHttpStatus", {
          msg: i18n.t(`${code}.${action}`),
          color: code,
          icon: SUCCESS ? "mdi-check-circle" : "mdi-alert-circle",
        });
      }
    },
    traduceItem(item){
      return i18n.t(item)
    },
    deepCopyFunction(inObject) {
      let outObject, value, key;

       if (typeof inObject !== "object" || inObject === null) {
         return inObject; // Return the value if inObject is not an object
       }
      // Create an array or object to hold the values
       outObject = Array.isArray(inObject) ? [] : {};
       inObject = JSON.parse(JSON.stringify(inObject));

       for (key in inObject) {
         value = inObject[key];

      //   Recursively (deep) copy for nested objects, including arrays
         outObject[key] = this.deepCopyFunction(value);
       }

      return outObject;
    },
    getDataSource(dataComponent = null) {
      let dataSource = this.deepCopyFunction(datasource.computed.fields());
      Object.keys(dataSource).forEach(key => {
        if (dataSource[key] && typeof dataSource[key] === 'object' && 'fromVuex' in dataSource[key]) {
          if ('findElementOnVuex' in dataSource[key] && 'atribute' in dataSource[key]) {
            const { variable: attr, atribute: childAttr, filter: filterConfig } = dataSource[key].findElementOnVuex
            if (dataComponent && dataComponent[attr]) {
              const valueToCompare = childAttr ? dataComponent[attr][childAttr] : dataComponent[attr]
              let element = store.state[dataSource[key].variable].find(item => item.name === valueToCompare);
              if (element && element[dataSource[key].atribute] && filterConfig) {
                let elementsToFilter = element[dataSource[key].atribute];
                Object.keys(filterConfig).forEach(filterItem => {
                  elementsToFilter = elementsToFilter.filter(item => item[filterItem] === filterConfig[filterItem]);
                })
                dataSource[key] = elementsToFilter;
              } else dataSource[key] = element ? element[dataSource[key].atribute] : [];
            } else dataSource[key] = [];
          } else dataSource[key] = store.state[dataSource[key].variable]
        }
        if (datasource.computed.fields()[key]?.transformDataColumnsDataSource) dataSource[key] = dataSource[key]?.map(e => e.alias) || [];
      });
      return dataSource;
    },
    setActions(actions) {
      let actionsToReturn;
      if (actions && actions.length) {
        actionsToReturn = this.deepCopyFunction(actions);
        actionsToReturn.forEach(action => {
          action.label = i18n.te(action.label) ? i18n.t(action.label) : action.label;
          if (action.color) action.color = this.envTheme[action.color] ? this.envTheme[action.color] : action.color;
          if (action.textColor) action.textColor = this.envTheme[action.textColor] ? this.envTheme[action.textColor] : action.textColor;
        });
      }
      return actionsToReturn;
    },
    executeAction(e, notifyResponse = true) {
      console.log(e, notifyResponse);
      if (store.state.itemToConfirmAction && !e.confirm) {
        store.dispatch("setItemToConfirmAction", undefined);
        return;
      }
      if (e.action === 'cancel' || (store.state.itemToConfirmAction && store.state.itemToConfirmAction.action === 'cancel')) {
        if (e.dirty && !store.state.itemToConfirmAction) {
          store.dispatch("setItemToConfirmAction", {
              ...e,
              title: i18n.t('discardChangesTitle'),
              text:  i18n.t('discardChangesText'),
            });
        } else if (e.confirm || !e.dirty) {
          store.dispatch("setItemToConfirmAction", undefined);
          store.dispatch("setItemToAddEditStepper", undefined);
          store.dispatch("setItemToAddEdit", undefined);
          store.dispatch("setItemToImport", undefined);
          store.dispatch("setItemToImportDictionary", undefined);
          store.dispatch("setIsExecuteProcessModalOpen", false);
          if (e.section === 'uploadFile') store.dispatch("setIsUploadFileModalOpen", false);
        }
        return;
      }
      if (e.data?.action) return this[e.data.action](e.data?.documentId || null)
      let { values, data } = e
      let { params: {
        element,
        msgAction,
        addPositionDynamicByDefault,
        collectionName,
        name,
        documentId 
        } = {},
        nullableFields,
        deleteNullValues
      } = data || {}

      let { alias, filterName } = values || {};

      if (values && msgAction !== 'addColumnSplit') values = this.transformValues(values, e);
      else if (values && alias) values.nameNotification = alias
      else if (values && filterName) values.nameNotification = filterName
      if (msgAction === 'deleteElementComponent' && addPositionDynamicByDefault) {
          values = this.setPositionColumns(element, msgAction, element);
          if (e.data && e.data.params && e.data.params.element) {
            if (e.data.params.element.alias) values.nameNotification = e.data.params.element.alias;
          }
      }
      let action = e.action || e.data?.params?.action || null;
      let actionSetted = e.data?.params?.actionSetted || undefined;

      if (action === "add") {
        if (e.section === 'Dictionaries' || e.section === 'Lists') {
          store.dispatch("setItemToSelect", {page: e.section, name: values.name});
        }
        firebaseTools.methods[e.data.method](collectionName, values, deleteNullValues ? nullableFields : []).then((result) => {
          if (e.section === 'InputDefinitions' || e.section === 'OutputDefinitions' || e.section === 'Dictionaries'){
            this.$router.push(store.state.actualView.path + result.id);
          }
        })
      } else if (action === "edit") {
        firebaseTools.methods[e.data.method](collectionName, documentId, values ?? element, actionSetted);
      } else {
        firebaseTools.methods[e.data.method](collectionName, documentId, name);
      }
    },
    // REQUESTS FIREBASE //

    getData(collection) {
      let response = [];
      let dataWithId = {};
      firebaseTools.methods.getAllData(collection).then(data => {
        if (data) data.forEach(doc => {
          dataWithId = { ...doc.data(), id: doc.id };
          response.push(dataWithId)
        });

        store.dispatch("setDataView", response);
        store.dispatch("setLoadingPage", false);
      })
    },
    setPositionColumns(values, action, elementUpdate) {
      let item = this.deepCopyFunction(store.state.dataView);
      if (!store.state.dataView.columns?.length) item.columns = [values]
      else {
        if (action === 'addColumn') item.columns.push(values);
        else if (action === 'editColumn' || action === 'deleteElementComponent') {
          let findElement = item.columns.find(element => element.position === elementUpdate.position && element.alias === elementUpdate.alias);
          if (action === 'editColumn') {
            item.columns.splice(item.columns.indexOf(findElement), 1, values);
            return item;
          } else item.columns.splice(item.columns.indexOf(findElement), 1);
        } 
      }
      item.columns.forEach((column, index) => column.position = index);
      return item
    },
    transformValues(values, e) {
      Object.keys(values).forEach(key=>{
        if(values[key] === undefined){
          values[key] = null
        }
      })
      let numberValues = ["position", "headerRow", "from", "to"];
      const {actualView: VIEW} = store.state
      const actualView = ['editColumn'].includes(e.data.params.msgAction) 
        ? VIEW ?? VIEW.parent 
        : VIEW.parent ?? VIEW 
      let actionOnActualview = actualView[e.data.params.msgAction === 'editColumn' ? 'componentActions' : 
                          e.data.params.componentActions ? 'componentActions' : 'actions']
                          .find(action => action.action === (e.data && e.data.params && e.data.params.msgAction ? e.data.params.msgAction : e.action));
      if (actionOnActualview && actionOnActualview.addValuesByDefault) {
        Object.keys(actionOnActualview.addValuesByDefault).forEach(element => {
          if (element === 'userGroup')  
            actionOnActualview.addValuesByDefault['userGroup'] = store.state.actualGroup.id;
          values = {...values, ...actionOnActualview.addValuesByDefault};
        })
      }
      if (actionOnActualview && actionOnActualview.addPositionDynamicByDefault) values.position = e.data && e.data.params && e.data.params.oldItem  ? e.data.params.oldItem.position : (store.state.dataView.columns && store.state.dataView.columns.length ? store.state.dataView.columns.length : 0);
      if (actionOnActualview && actionOnActualview.bodyParamsElementsToOmit) actionOnActualview.bodyParamsElementsToOmit.forEach(value => delete values[value]);
      if ('nullable' in values && !values.nullable) values.nullable = false;
      if ('sumValues' in values && !values.sumValues) values.sumValues = false;
      if (values.allowedValuesValidation) values.allowedValuesValidation = {id: e.values.allowedValuesValidation.id};
      if ('transformationType' in values && values.transformationType === "ADD_TIME_STEP") {
        if (!values.dateColumnName && !values.timeStepColumnName && !values.timeStepValue && !values.arithmeticOperator && !values.limitDate) values.addTimeStepTransformation = null;
        else values.addTimeStepTransformation = {dateColumnName: values.dateColumnName, timeStepColumnName: values.timeStepColumnName, timeStepValue: values.timeStepValue, arithmeticOperator: values.arithmeticOperator, limitDate: values.limitDate};
        delete values.dateColumnName;
        delete values.timeStepColumnName;
        delete values.timeStepValue;
        delete values.arithmeticOperator;
        delete values.limitDate;
      } else if (!('transformationType' in values) || ('transformationType' in values && values.transformationType !== "ADD_TIME_STEP" )){
        delete values.arithmeticOperator
        delete values.dateColumnName
      }
      if ('inputDescriptorReference' in values && values.inputDescriptorReference && values.inputDescriptorReference.id && values.inputDescriptorReference.name) {
        values.inputDescriptorReference = {
          key: values.inputDescriptorReference.id,
          value: values.inputDescriptorReference.name
        };
      }
      numberValues.forEach(numberItem => {
        if (numberItem in values && values[numberItem]) {
          values[numberItem] = Number(values[numberItem]);
        }
      });
      if ('to' in values && 'rangeType' in values && 'from' in values) {
        if (!values.to && !values.rangeType && !values.from) values.range = null;
        else values.range = {to: values.to, from: values.from, type: values.rangeType};
        delete values.to;
        delete values.from;
        delete values.rangeType;
      }
      if (actionOnActualview?.restOneToPosition) values.position = values.position - 1;
      if (actionOnActualview?.addPositionDynamicByDefault || store.state.actualView.tableActions?.find(({action})=> action === (e?.data?.params?.msgAction || e?.action))?.addPositionDynamicByDefault) this.setPositionColumns(values,e.data.params.msgAction, e.data.params.oldItem);
      
      return values;
    },
    setCurrentValues(item, dataSource) {
      let copyItem = this.deepCopyFunction(item);
      let values = item && item.currentDataValues ? item.currentDataValues : null;
      if (values && 'allowedValuesValidation' in values && values.allowedValuesValidation) {
        let allowedValuesValidationField = dataSource.allowedValuesValidation.find(element => element.id === values.allowedValuesValidation.id);
        allowedValuesValidationField.value = allowedValuesValidationField.id;
        copyItem.currentDataValues.allowedValuesValidation = allowedValuesValidationField;
      }
      if (values && 'dictionaryTransformation' in values && values.dictionaryTransformation) copyItem.currentDataValues.dictionaryTransformation =  dataSource.dictionaries.find(element => element.id === values.dictionaryTransformation.id);
      if (values && 'range' in values && values.range) {
        copyItem.currentDataValues.from = values.range.from;
        copyItem.currentDataValues.to = values.range.to;
        copyItem.currentDataValues.rangeType = values.range.type;
      }
      if (values && 'addTimeStepTransformation' in values && values.addTimeStepTransformation) {
        copyItem.currentDataValues.dateColumnName = values.addTimeStepTransformation.dateColumnName;
        copyItem.currentDataValues.timeStepColumnName = values.addTimeStepTransformation.timeStepColumnName;
        copyItem.currentDataValues.timeStepValue = values.addTimeStepTransformation.timeStepValue;
        copyItem.currentDataValues.arithmeticOperator = values.addTimeStepTransformation.arithmeticOperator;
        copyItem.currentDataValues.limitDate = values.addTimeStepTransformation.limitDate;
      }
      return copyItem;
    },
    getDataById(collection, documentId) {
      this.getDataByDocumentId(collection, documentId).then(response => {
        if (response.exists()) {
          console.log("Document data:", response.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    },   
    setCloneName({name: element},globalValues){
        const clones = globalValues.filter(({name})=>name.includes(i18n.t('clon',{element}))).map(({name})=>name).sort()
        const cloneName = (i18n.t('clon',{element}).concat(!clones.length || !clones.some(el=>el===i18n.t('clon',{element}))
            ? ''
            : ` (${clones?.at(-1).includes(`(${clones.length})`) ? clones.length+1 : clones.length})`))
        return cloneName
    },
    deleteAllProcess() {
      let params = new FormData();
      params.append("userGroup", store.state.actualGroup.id);

      this.httpRequest(
        "post",
        [
          window.PROJECT_ENV.BASE_URL_DELETE_PROCESS + "delete/allProcess",
          params,
        ],
        false
      )
        .then((response) => {
          this.handleHttpStatus(response,'delete.allProcess');
        })
        .catch((error) => {
          this.handleHttpStatus(error);
        })
        .finally(()=>{
          store.dispatch("setItemToConfirmAction", undefined);
        });
    },
    deleteProcess(processId) {
      let params = new FormData();
      params.append("processId", processId);

      this.httpRequest(
        "post",
        [window.PROJECT_ENV.BASE_URL_DELETE_PROCESS + "delete/process", params],
        false
      )
        .then((response) => {
          this.handleHttpStatus(response,'delete.process');
        })
        .catch((error) => {
          this.handleHttpStatus(error);
        })
        .finally(()=>{
          store.dispatch("setItemToConfirmAction", undefined);
        });
    },
    collectionStore(collection) {
      const map = {
        inputDescriptors: 'inputDefinitionsData',
        outputDescriptors: 'outputDefinitionsData',
        dictionaries: 'dictionariesData',
        lists: 'listsData'
      };
      return map[collection]
    },
    collectionStoreDispatcher(collection) {
      const map = {
        inputDescriptors: 'setInputDefinitionsData',
        outputDescriptors: 'setOutputDefinitionsData',
        dictionaries: 'setDictionariesData',
        lists: 'setListsData'
      };
      return map[collection]
    },
    checkAreEqualObjects(obj1, obj2) {
      if (obj1 === obj2) {
          return true;
      }

      if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
          return false;
      }

      let keys1 = Object.keys(obj1);
      let keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
          return false;
      }

      for (let key of keys1) {
          if (!keys2.includes(key) || !this.checkAreEqualObjects(obj1[key], obj2[key])) {
              return false;
          }
      }

      return true;
  },
  setConfigIconCheckOnTables(isTrue) {
    return {
      color: isTrue ? 'var(--primary)' : '#999999',
      icon:  isTrue ? 'mdi-check-circle' : 'mdi-close-circle'
    }
  },
  },
}
