<template>
  <div :style="{ 'max-height': `calc(100vh - ${headerContainer}px)` }" class="container-list">
    <div v-for="(listItem, index) in dataComponent" :key="index" class="card-list">
      <Card class="pr-2 ma-20 mt-2">
        <CardHeader :to="actualView.path + listItem.id">
          {{ listItem.name }}

          <template v-slot:afterHeader>
            <Actions v-if="cardActions && cardActions.length" :actions="cardActions"
              @action-selected="onActionSelected($event, listItem)" />
          </template>
        </CardHeader>
      </Card>
    </div>
  </div>
</template>

<script>

import i18n from "@/plugins/i18n";
import { uuid } from 'vue-uuid';
import { mapActions, mapState } from "vuex";
import { tools } from "@/mixins/tools";
import editableDataFields from "@/mixins/editable-data-fields";
import editableDataFieldsStepper from "@/mixins/editable-data-fields-stepper";

export default {
  name: "List",
  data: () => ({
  }),
  props: {
    dataComponent: { type: Array },
    headerHeight: { type: Number }
  },
  mixins: [tools],
  methods: {
    ...mapActions([
      "setItemToConfirmAction",
      "setItemToAddEdit",
      "setItemToAddEditStepper",
    ]),
    onActionSelected(event, listItem) {
      let copySelectedItem = this.deepCopyFunction(listItem);
      let fields = this.deepCopyFunction(
        editableDataFields.computed[this.actualView.name]()[event.action]?.fields
      );
      let configEditableDataFields = this.deepCopyFunction(
        editableDataFields.computed[this.actualView.name]()[event.action]?.config
      );
      let dataSource = this.getDataSource();
      const {name:section, actions: actualViewActions, tableActions, componentActions} = this.actualView
      const actions = [actualViewActions, tableActions, componentActions].flat().filter(Boolean)
      let actionOnActualView = actions.find(
        (action) => action.action === event.action
      );
      let globalValues =
        actionOnActualView && actionOnActualView.globalValues
          ? this.deepCopyFunction(this.dataView[actionOnActualView.globalValues])
          : this.deepCopyFunction(this.dataView);

      let {
        globalValues: globalValuesForAction,
        addPositionDynamicByDefault = false
      } = actionOnActualView || {};

      const editableFieldsStepper = editableDataFieldsStepper.computed[section] && this.deepCopyFunction(editableDataFieldsStepper.computed[section]()[event.action]);
      let { config: stepperConfig, steps, alerts } = editableFieldsStepper || {};
      let dictionary;

      switch (event.action) {
        case "delete":
          this.setItemToConfirmAction({
            title: i18n.t(`delete${this.actualView.name}`),
            text: i18n.t(`delete${this.actualView.name}Question`),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: listItem.id,
                name: copySelectedItem.name
              },
              method: "deleteDocument",
            },
          });
          break;
        case "deleteDictionary":
          this.setItemToConfirmAction({
            title: i18n.t(`delete${this.actualView.name}`),
            text: i18n.t(`delete${this.actualView.name}Question`),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: listItem.id,
                name: copySelectedItem.name
              },
              method: "deleteDocument",
            },
            alerts: [
              {
                type: 'warning',
                icon: 'mdi-alert',
                text: i18n.t('deleteDictionaryAlert'),
              },
            ]
          });
          break;
        case "edit":
          this.setItemToAddEdit({
            section,
            fields,
            dataSource,
            configEditableDataFields,
            currentDataValues: copySelectedItem,
            globalValues: this.deepCopyFunction(this.dataView),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: copySelectedItem.id,
              },
              method: "updateDocument",
            },
          });
          break;
        case "clone":
          delete listItem.id
          this.setItemToAddEdit({
            section,
            fields,
            globalValues: this.deepCopyFunction(this.dataView),
            currentDataValues: { ...listItem, name: this.setCloneName(listItem, this.dataView) },
            configEditableDataFields,
            dataSource,
            cloneItem: true,
            data: {
              params: { collectionName: this.actualView.get[0].collection },
              method: "insertDocument",
            },
          });
          break;
        case "cloneDictionary":
          globalValues = globalValuesForAction ? this.deepCopyFunction(this.dataComponent[globalValuesForAction]) : this.deepCopyFunction(this.dataComponent);
          dictionary = { ...listItem, name: this.setCloneName(listItem, this.dataView) }
          this.setItemToAddEditStepper({
            section,
            dataSource,
            globalValues,
            steps,
            addPositionDynamicByDefault,
            action: 'add',
            config: stepperConfig,
            currentDataValues: this.prepareDictionaryToStepper(dictionary),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: listItem.id,
                msgAction: event.action,
                componentActions: true,
              },
              method: "insertDocument",
            },
          });
          break;
        case 'editDictionary':
          globalValues = globalValuesForAction ? this.deepCopyFunction(this.dataComponent[globalValuesForAction]) : this.deepCopyFunction(this.dataComponent);
          dictionary = { ...listItem };

          this.setItemToAddEditStepper({
            section,
            dataSource,
            globalValues,
            steps,
            addPositionDynamicByDefault,
            action: 'edit',
            config: stepperConfig,
            alerts,
            currentDataValues: this.prepareDictionaryToStepper(dictionary),
            data: {
              params: {
                collectionName: this.actualView.get[0].collection,
                documentId: listItem.id,
                msgAction: event.action,
                componentActions: true,
              },
              method: "updateDocument",
            },
          });
          break;
      }
    },

    prepareDictionaryToStepper(dictionary) {
      const result = { name: dictionary.name, dictionaryKeys: [], dictionaryValues: [], data: new Map() };
      if (!dictionary.dictionaryValues || dictionary.dictionaryValues.length === 0) {
        result.dictionaryKeys.push({ uuid: uuid.v4(), value: '' });
        result.dictionaryValues.push({ uuid: uuid.v4(), value: '' });
        result.data.set(0, [{ uuid: result.dictionaryKeys[0].uuid, value: '' }]);
        result.data.set(0, [{ uuid: result.dictionaryValues[0].uuid, value: '' }]);
        return result;
      }
      dictionary.dictionaryValues.forEach((row, index) => {
        row.row.forEach(attribute => {
          const attrToPushData = attribute.cellIsKey ? 'dictionaryKeys' : 'dictionaryValues';
          if (index === 0) {
            result[attrToPushData].push({ uuid: uuid.v4(), value: attribute.entry.key });
          }
          if (!result.data.has(index)) {
            result.data.set(index, []);
          }
          const id = result[attrToPushData].find(key => key.value === attribute.entry.key).uuid;
          result.data.get(index).push({ uuid: id, value: attribute.entry.value });
        })
      });

      return result;
    }
  },
  computed: {
    ...mapState(["actualView", "dataView"]),
    cardActions() {
      return this.setActions(this.actualView.componentActions);
    },
    headerContainer() {
      return this.headerHeight + 32;
    }
  }
};
</script>

<style lang="scss" scoped>
.container-list {
  overflow: auto;
  padding-right: 6px;
  padding-left: 6px;

  .card-list:not(:last-child) {
    margin-bottom: 20px;
  }

  .card-list:last-child {
    margin-bottom: 10px;
  }
}

::v-deep {
  .card-after-header {
    display: flex;
  }
}
</style>
