<template>
  <v-form ref="form">
    <v-autocomplete 
      :items="items" 
      :item-text="['name']" 
      :item-value="itemValue" 
      v-model="selectedItem" 
      :label="label"
      :rules="[v => !!v || $t('required')]" 
      class="mt-5"
      :no-data-text="$t(noDataText)"
    >
    </v-autocomplete>
  </v-form>
</template>

<script>
export default {
  name: 'AutocompleteComponent',
  props: {
    dataSource: { type: Object },
    submitFormComponent: { type: Boolean },
    resetComponent: { type: Boolean },
    currentDataValues: { type: Object },
    config: { type: Object },
  },
  data() {
    return {
      selectedItem: undefined,
      dirty: false,
    };
  },
  computed: {
    items() {
      return this.dataSource[this.config.dataSourceAttr] || [];
    },
    itemValue() {
      return [this.config.formAttribute === 'delimiter' ? 'value' : 'id'];
    },
    label() {
      return this.$t(this.config.formAttribute + 'Label');
    },
    noDataText(){
      return this.config.noDataText ?? 'noDataAutocomplete'
    }
  },
  methods: {
    emitIsValidForm() {
      this.$emit('isValid', this.$refs.form.validate());
    },
    emitIsDirty() {
      this.$emit('isDirty', { dirty: this.dirty });
    }
  },
  watch: {
    selectedItem(v) {
      if (v) {
        if (!this.dirty) this.dirty = true;
        if (this.config.formAttribute === 'delimiter') this.$emit('update', { delimiter: v });
        else {
          const item = this.dataSource[this.config.dataSourceAttr].find(e => e.id === v);
          const itemToEmit = [{ key: item.id, value: item.name }];
          this.$emit('update', { inputDescriptorInfo: itemToEmit });
        }
      } else this.$emit('update', null);
      this.emitIsValidForm();
    },
    submitFormComponent(v) {
      if (v) {
        this.emitIsValidForm();
        this.$nextTick(() => {
          this.emitIsDirty();
        })
      }
    },
  },
  created() {
    const value = this.currentDataValues && this.currentDataValues[this.config.formAttribute]
    if (value) this.selectedItem = Array.isArray(value) ? value[0].key : value
  }
};
</script>